import React, { Component } from "react";
import {
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import { SpreadSheets, Worksheet } from "@grapecity/spread-sheets-react";
import * as spreadExcel from "@grapecity/spread-excelio";
import GC from "@grapecity/spread-sheets";
import "@grapecity/spread-sheets-designer-resources-en";
import "@grapecity/spread-sheets-designer-react";
import "@grapecity/spread-sheets-designer/styles/gc.spread.sheets.designer.min.css";
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css";

// import { storage } from '../firebase';
import { firebaseDB, firebaseStorage } from "service/firebase";
import { replaceWithPublicURL } from "service/firebase";
import config from "../config/configForDesigner.json";
import commandMap from "../config/commandMap.json";
import TopNav from "./TopNav";
import FlagButton from "./FlagButton";
import BottomNav from "./BottomNav";

import sampleData from "config/exam.json";
import { MAX_ATTEMPTS } from "config/valiables";

// var SpreadJSKey =
//   "exceltest-96c8c.web.app,532815772875756#B0dRzRpN7bIdGTVhzVnNkM4FUdjJUc7YWYBVVSChVOJtmTidUYrc5b5UHaQhmV9AjRWJkbslESxYUMsFWTKd5YQ54aahmM6dEO9ZjdnN4UtpVOpxUWHFjN5tScKF6d9kjTqVzNrQ4Nj9kdqJna0ZjSyBlZkdVZ4UVU63CMVVFeQBVTzIFW0hzVml6ZDdnQ8BzYhllZqFVWwpkV8oXSyFkS5xmWFVWcJtkdipkMMhWcWN6diZDdQRFcOhVYmNzTk56SHpUeQhUQuVkVlVlN5NWd9ADWiNWU4BnZ724ZM3SN95WcNZXQ8lmUVt6RrQHSapGWiojITJCLiQjNwMjMykTNiojIIJCL6kDM6IDO9ETO0IicfJye35XX3JSRFlkViojIDJCLiQTMuYHIgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiYjM5ETOwAiNxMDMxIDMyIiOiQncDJCLiAHch9iYldnLjhzY6kTL4NXZ4xWZjhXZiojIz5GRiwiI73GbsVmR9JXY5R7YBJiOiEmTDJCLiYTN7UzN8IzN7UTM8IzM5IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBJGT4J7YQRXUo3WTwIUQ4lUOQlUWqNUZzJmUvR6UlNzUmdnNWtmY8ZEUrYXdxsGT5cEcaJWNp3meMhDMslVUQ5Gb4h4N5J5ZMNmWxQHbPVFT5g5bENWcaVFRrRXe9JmQnhzTekVa";
var SpreadJSKey = "actuaryfellows.com,532815772875756#B0xbgdFO53kRhJlZS9mSShVZPtEVz24TVJTNY54KXBHValDUzMlS5EHSZ5GVodDVq3SYBpXTvkUZMhzNGBneRlnMGZDWWBVeKNXcPllV8A7Lk36NXl6V9FVNoZVVIZUN5p6UJVjd6gzM7N4M0FVaEVXdRB7cpFjSFdXbjZWORlmM7ZHc72iUJRmR7EVZVtiQE5WVyhFMT56bxdkUJdzQq54MNJHU9p4LWNEWvZlZoNzSvkDRxQTZqNUYUtUQMhjd8MFbthVQIdEdqlGUyITT8VEaC3iaQpleI5kWSJTSjpkcE9WRzUkeSl4S4FTVUtkI0IyUiwiICZjQxEjQyIjI0ICSiwCM4AzN9cDO5ITM0IicfJye#4Xfd5nIFVUSWJiOiMkIsICNx8idgAyUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICOwATNyEDI5ADNwEjMwIjI0ICdyNkIsISbvNmLzd7bsxWZmlnchVHdjFmI0IyctRkIsIydvxGblZUeyFWd4NWQiojIh94QiwiI6UzN5cDOyczN5EDOyMTNiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZvAnWERTZLR5ZndnMXdmc0xkNth5Nzd4ZZ3WVjpnQWNkYtZndZV6Q4pVbwQkbJlFUjpEcrtEMKFDRzRUYmJHdrdVTTpXarQGM4lEa4V5RyFzQ6YkbNlndHlDRrdDOwdmRrY4UzZVNhJ";
GC.Spread.Sheets.LicenseKey = SpreadJSKey;
GC.Spread.Sheets.Designer.LicenseKey =
  "863476749274518#B0JBfbnZ4SHhXWxQnehJDW6FFM8VXZGNXYXdkQTFlZrcFdhtWQLNDdXV7LDpnRJF5VOhWT6E6VHZXcH3GRCNGR6l5dVZjN7olbDZGSDNjSxVWVDtiSEB5aQV7b7VWUyN6TKJWNjlFOrNXdzwES7dzaQFkQx4kSrZ6KkpmbwtCduBHWMZTaz3SWPlEd88UQYVjTU3WMkhWVvpEbGFVQ4k7NlZ5cpt4Ra5WWxtGTItSMjNXNupWewNTe6ZEVs56dSV7VyYDdTRTMRRXSF3GMxE4UC3yZiojITJCLiUUM6M4QEZjNiojIIJCLzQTOzUTN7UDN0IicfJye#4Xfd5nIZZTR7IiOiMkIsICNx8idg86bkRWQtIXZudWazVGRtMlSkFWZyB7UiojIOJyebpjIkJHUiwiIxEDOzgDMggDMzATMyAjMiojI4J7QiwiI8ETN4cjM9QzN6cDNzYDOiojIklkI1pjIEJCLi4TPnhHdl5WYndVR6RXaqVTZTRDVvQFa7NXUWZ6cwlVOapkUMZ5KJxkZ4dVc9QDMXdzdl3CN9Y6bjNUYwBnQoZUd7dHRFlFNUhjSPhmda5EVLZ4YLdTTQZXczM6a0FUNOtyd5MFOvpHcMZVSDZUY7lzTO9Edwx4b5tUVpF7VVNDR9sCcNF3e";
spreadExcel.LicenseKey = SpreadJSKey;

let row = 0;
let col = 0;

// Display copy, cut, and paste when double clicking a cell, highlight the text, and use copy shortcut key
let old = GC.Spread.Sheets.CellTypes.Text.prototype.isReservedKey;
GC.Spread.Sheets.CellTypes.Text.prototype.isReservedKey = function (
  e,
  context
) {
  row = context.row;
  col = context.col;
  //cell type handle ctrl+c,v,x key by itself
  if (context.isEditing) {
    return (
      (e.keyCode === GC.Spread.Commands.Key.c ||
        e.keyCode === GC.Spread.Commands.Key.v ||
        e.keyCode === GC.Spread.Commands.Key.x) &&
      e.metakey &&
      !e.shiftKey &&
      !e.altKey
    );
  } else {
    old.apply(this, arguments);
  }
};

class ExamSheet extends Component {
  constructor(props) {
    super(props);
    this.spread = null;
    this.designer = null;
    this.sheetName = "Practice Test";
    this.hostStyle = {
      width: "100%",
      height: "60vh",
    };
    this.columnWidth = 200;
    this.state = {
      spread: null,
      link: "",
      exam_id: "",
      practice_id: "",
      type: "",
      pageCount: 0,
      pagesData: [],
      currentPage: 0,
    };
    this.markIncomplete = this.markIncomplete.bind(this);
    this.flagAPage = this.flagAPage.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.keyDownFunction = this.keyDownFunction.bind(this);
  }

  // Mark the page visited
  markIncomplete(value) {
    if (
      this.state.pagesData[value] &&
      this.state.pagesData[value].status === "Unseen"
    ) {
      let temp = this.state.pagesData;
      temp[value].status = "Incomplete";
      this.setState({ pagesData: temp });
    }
  }

  flagAPage() {
    let temp = this.state.pagesData;
    temp[this.state.currentPage].flag = !temp[this.state.currentPage].flag;
    this.setState({ pagesData: temp });
  }

  setCurrentPage(value) {
    this.setState({ currentPage: value });
  }

  // Mark the page completed if the user type something
  keyDownFunction() {
    if (this.state.pagesData[this.state.currentPage].status !== "Completed") {
      let temp = this.state.pagesData;
      temp[this.state.currentPage].status = "Completed";
      this.setState({ pagesData: temp });
    }
  }

  async loadSheetFromDB() {
    const exam_id = this.props.match.params.examid;
    const practice_id = this.props.match.params.practiceid;
    const type = this.props.match.params.type;
    this.setState({ exam_id: exam_id });
    this.setState({ practice_id: practice_id });
    this.setState({ type: type });
    const currentExam = this.props.exams.find((el) => el.id == exam_id);
    var link = "";
    try {
      if (parseInt(type) != NaN && parseInt(type) > MAX_ATTEMPTS) {
        const images = firebaseStorage.ref(
          `A_Exams/attempts/${exam_id}/${parseInt(type) - MAX_ATTEMPTS}/${
            this.props.authUserId
          }_${practice_id}.txt`
        );
        link = await images.getDownloadURL();
      } else if (parseInt(type) != NaN && parseInt(type) <= MAX_ATTEMPTS) {
        const images = firebaseStorage.ref(
          `A_Exams/attempts/${exam_id}/${parseInt(type)}/${
            this.props.authUserId
          }_${practice_id}.txt`
        );
        link = await images.getDownloadURL();
      } else {
        if (practice_id == "demo") {
          link = type === "solution" ? currentExam.solution : currentExam.demo;
        } else {
          const currentPractice = currentExam.practices.find(
            (el) => el.id == practice_id
          );
          const images =
            type === "solution"
              ? firebaseStorage.refFromURL(currentPractice.solution)
              : firebaseStorage.refFromURL(currentPractice.link);
          link = await images.getDownloadURL();
        }
        console.log(link, "link it aif s");
      }
      console.log(this.props.exams, link);
      this.setState({ link: link });
    } catch (err) {
      console.log(err);
      const currentPractice = currentExam.practices.find(
        (el) => el.id == practice_id
      );
      const images =
        type === "solution"
          ? firebaseStorage.refFromURL(currentPractice.solution)
          : firebaseStorage.refFromURL(currentPractice.link);
      link = await images.getDownloadURL();
      console.log(this.props.exams, link);
      this.setState({ link: link });
    }
  }

  async initSpread(spread) {
    this.spread = spread;
    await this.loadSheetFromDB();
    this.setState({ spread: spread });
    let excelIo = new spreadExcel.IO();

    // Get the file from firebase
    // const images = storage.ref('files/123');
    // let url = await images.getDownloadURL();
    // let res = await fetch(url);
    let res = await fetch(this.state.link);
    let excelFile = await res.blob();

    // Read the JSON data file
    const fr = new FileReader();

    fr.addEventListener("load", async (e) => {
      const data1 = JSON.parse(e.target.result);

      // Add the data from the file
      await spread.fromJSON(data1);

      let pageCount = await spread.getSheetCount();
      this.setState({ pageCount: pageCount });
      let sheet;
      // Set which sheet to display first
      spread.setActiveSheetIndex(0);

      // Set the number of row and column for each pages
      for (let i = 0; i < pageCount; i++) {
        sheet = spread.getSheet(i);
        sheet.setRowCount(200);
        sheet.setColumnCount(40);

        this.setState({
          pagesData: [
            ...this.state.pagesData,
            { id: i, status: "Unseen", flag: false },
          ],
        });
      }

      this.markIncomplete(0);

      sheet = spread.getActiveSheet();

      // Option to put for the Menu when you right click
      let contextMenuList = [];
      contextMenuList.push(spread.contextMenu.menuData[0]); // Cut
      contextMenuList.push(spread.contextMenu.menuData[1]); // Copy
      contextMenuList.push(spread.contextMenu.menuData[5]); // Paste
      contextMenuList.push(spread.contextMenu.menuData[14]); // A Separator
      contextMenuList.push(spread.contextMenu.menuData[53]); // Format Cells
      contextMenuList[2].text = "Paste";
      spread.contextMenu.menuData = contextMenuList;

      // Hide the Tab Strip
      spread.options.tabStripVisible = false;

      var internalCopy = {
        canUndo: true,
        execute: function (spread, options, isUndo) {
          var Commands = GC.Spread.Sheets.Commands;
          if (isUndo) {
            Commands.undoTransaction(spread, options);
            return true;
          } else {
            Commands.startTransaction(spread, options);
            spread.suspendPaint();
            spread.commandManager().execute({
              cmd: "copy",
              sheetName: spread.getActiveSheet().name(),
            });
            spread.resumePaint();
            Commands.endTransaction(spread, options);
            return true;
          }
        },
      };
      var internalPaste = {
        canUndo: true,
        execute: function (spread, options, isUndo) {
          var Commands = GC.Spread.Sheets.Commands;
          if (isUndo) {
            Commands.undoTransaction(spread, options);
            return true;
          } else {
            Commands.startTransaction(spread, options);
            spread.suspendPaint();

            spread.commandManager().execute({
              cmd: "paste",
              sheetName: spread.getActiveSheet().name(),
            });
            spread.resumePaint();
            Commands.endTransaction(spread, options);
            return true;
          }
        },
      };
      var internalCut = {
        canUndo: true,
        execute: function (spread, options, isUndo) {
          var Commands = GC.Spread.Sheets.Commands;
          if (isUndo) {
            Commands.undoTransaction(spread, options);
            return true;
          } else {
            Commands.startTransaction(spread, options);
            spread.suspendPaint();

            spread.commandManager().execute({
              cmd: "cut",
              sheetName: spread.getActiveSheet().name(),
            });
            spread.resumePaint();
            Commands.endTransaction(spread, options);
            return true;
          }
        },
      };
      var makeBold = {
        canUndo: true,
        execute: function (spread) {
          let style = new GC.Spread.Sheets.Style();
          style.font = "Bold 12pt Calibri";
          let sheet = spread.getActiveSheet();
          sheet.setStyle(row, col, style);
        },
      };
      var makeItalic = {
        canUndo: true,
        execute: function (spread) {
          let style = new GC.Spread.Sheets.Style();
          style.font = "Italic 12pt Calibri";
          let sheet = spread.getActiveSheet();
          sheet.setStyle(row, col, style);
        },
      };
      var makeUnderline = {
        canUndo: true,
        execute: function (spread) {
          let style = new GC.Spread.Sheets.Style();
          style.textDecoration = GC.Spread.Sheets.TextDecorationType.underline;
          let sheet = spread.getActiveSheet();
          sheet.setStyle(row, col, style);
        },
      };
      var selectAll = {
        canUndo: true,
        execute: function (spread, options, isUndo) {
          var Commands = GC.Spread.Sheets.Commands;
          if (isUndo) {
            Commands.undoTransaction(spread, options);
            return true;
          } else {
            Commands.startTransaction(spread, options);
            spread.suspendPaint();
            let sheet = spread.getActiveSheet();
            options.cmd = "selectAll";
            sheet.clearSelection();
            sheet.addSelection(
              0,
              0,
              sheet.getRowCount(),
              sheet.getColumnCount()
            );
            spread.resumePaint();
            Commands.endTransaction(spread, options);
            return true;
          }
        },
      };

      var commandManager = spread.commandManager();
      commandManager.register("internalCopy", internalCopy);
      commandManager.register("internalPaste", internalPaste);
      commandManager.register("internalCut", internalCut);
      commandManager.register("makeBold", makeBold);
      commandManager.register("makeItalic", makeItalic);
      commandManager.register("makeUnderline", makeUnderline);
      commandManager.register("selectAll", selectAll);

      var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

      // For Mac
      if (isMac) {
        commandManager.setShortcutKey(
          "internalCopy",
          67,
          false,
          false,
          false,
          true
        );
        commandManager.setShortcutKey(
          "internalPaste",
          86,
          false,
          false,
          false,
          true
        );
        commandManager.setShortcutKey(
          "internalCut",
          88,
          false,
          false,
          false,
          true
        );
        commandManager.setShortcutKey(
          "makeBold",
          66,
          false,
          false,
          false,
          true
        );
        commandManager.setShortcutKey(
          "makeItalic",
          73,
          false,
          false,
          false,
          true
        );
        commandManager.setShortcutKey(
          "makeUnderline",
          85,
          false,
          false,
          false,
          true
        );
        commandManager.setShortcutKey(
          "selectAll",
          65,
          false,
          false,
          false,
          true
        );
      }
      // For Window
      else {
        commandManager.setShortcutKey(
          "internalCopy",
          67,
          true,
          false,
          false,
          false
        );
        commandManager.setShortcutKey(
          "internalPaste",
          86,
          true,
          false,
          false,
          false
        );
        commandManager.setShortcutKey(
          "internalCut",
          88,
          true,
          false,
          false,
          false
        );
        commandManager.setShortcutKey(
          "makeBold",
          66,
          true,
          false,
          false,
          false
        );
        commandManager.setShortcutKey(
          "makeItalic",
          73,
          true,
          false,
          false,
          false
        );
        commandManager.setShortcutKey(
          "makeUnderline",
          85,
          true,
          false,
          false,
          false
        );
        commandManager.setShortcutKey(
          "selectAll",
          65,
          true,
          false,
          false,
          false
        );
      }
    });
    fr.readAsText(excelFile);
    this.getDesigner();
  }

  getDesigner(designer) {
    // For the dropdown
    config.commandMap = commandMap;
    // Initialize designer with customizable configuration and spread component
    this.designer = new GC.Spread.Sheets.Designer.Designer(
      document.getElementById("designerHost"),
      config,
      this.spread
    );
  }
  render() {
    const { exams } = this.props;
    return (
      <>
        {!this.props.loading && (
          <div>
            {parseInt(this.state.type) <= MAX_ATTEMPTS && (
              <TopNav
                currentPage={this.state.currentPage}
                pageCount={this.state.pageCount}
                spread={this.state.spread}
                examId={this.state.exam_id}
                practiceId={this.state.practice_id}
                type={this.state.type}
              />
            )}
            <FlagButton
              pagesData={this.state.pagesData}
              currentPage={this.state.currentPage}
              flagAPage={this.flagAPage}
            />

            <div
              onContextMenu={(e) => e.preventDefault()}
              onKeyDown={this.keyDownFunction}
            >
              <SpreadSheets
                id="ss"
                hostStyle={this.hostStyle}
                workbookInitialized={(spread) => this.initSpread(spread)}
              >
                <Worksheet
                  name={this.sheetName}
                  rowCount={100}
                  colCount={40}
                ></Worksheet>
              </SpreadSheets>
              <div id="designerHost" style={{ width: "100%" }}></div>
            </div>

            <BottomNav
              currentPage={this.state.currentPage}
              setCurrentPage={this.setCurrentPage}
              spread={this.state.spread}
              pageCount={this.state.pageCount}
              markIncomplete={this.markIncomplete}
              handleClickOpen={this.handleClickOpen}
              pagesData={this.state.pagesData}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  auth: { data: userData },
  exam: { filter, data, loading },
}) => ({
  authUserId: userData && userData.uid ? userData.uid : null,
  filter,
  exams: loading ? sampleData : data,
  loading,
});

export default withRouter(connect(mapStateToProps)(ExamSheet));
