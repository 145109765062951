import React, { useEffect, useState } from "react";
import {
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firebaseDB } from "service/firebase";
import Announcement from "components/Announcement";
import PurchaseExam from "components/PurchaseExam";
import { MainLayout } from "components/Layout";

import { updateCurrentExam } from "redux/actions/ExamActions";
import { purchaseFiltered } from "utils/filter";

import sampleData from "config/sample.json";

const HomeIn = ({ loading, exams, purchase, userType, dispatch }) => {
  const { path } = useRouteMatch();
  const [selectedExam, setSelectedExam] = useState();
  const [selectedPurchase, setSelectedPurchase] = useState();

  const handleChange = (exam) => {
    setSelectedExam(exam);
    const currentPurchase = purchase.find((el) => el.exam_id == exam.id);
    setSelectedPurchase(currentPurchase);
    dispatch(updateCurrentExam(exam));
  };

  return (
    <MainLayout
      basePath="/home"
      loading={loading}
      selected={selectedExam}
      onSelect={handleChange}
      exams={exams}
    >
      {selectedExam && <Announcement data={selectedExam} />}
      {selectedExam && (
        <PurchaseExam
          data={selectedExam}
          userType={userType}
          purchase={selectedPurchase}
        />
      )}
    </MainLayout>
  );
};

HomeIn.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  userType: PropTypes.oneOf(["pro", "free"]).isRequired,
};

const mapStateToProps = ({
  exam: { filter, data, loading, purchase, current_exam },
  auth: { data: userData },
}) => {
  const current_purchase = current_exam
    ? purchase.find((el) => el.exam_id == current_exam.id)
    : {};
  const userType =
    current_exam &&
    current_purchase?.practice_id &&
    purchaseFiltered(current_purchase.purchase_date).includes("flashcard")
      ? "pro"
      : "free";
  return {
    filter,
    exams: loading ? sampleData : data,
    purchase,
    loading,
    userType,
  };
};

export default withRouter(connect(mapStateToProps)(HomeIn));
