import { replaceWithPublicURL } from 'service/firebase';

export const UPDATE_FILTER = 'UPDATE_FILTER';
export const CARD_ADDED = 'CARD_ADDED';
export const CARD_ADDED_ALL = 'CARD_ADDED_ALL';
export const CARD_REMOVED_ALL = 'CARD_REMOVED_ALL';
export const CARD_CHANGED = 'CARD_CHANGED';
export const CARD_REMOVED = 'CARD_REMOVED';
export const CARD_LOADING = 'CARD_LOADING';

export const cardLoadingStatus = status => dispatch => {
  dispatch({
    type: CARD_LOADING,
    payload: {
      status,
    },
  });
};

export const cardAddedAll = datas => async dispatch => {
  const promises = datas.map(async snap => {
    const data = await replaceWithPublicURL(snap);
    return data;
  });

  const cards = await Promise.all(promises);

  dispatch({
    type: CARD_ADDED_ALL,
    payload: {
      datas: cards,
    },
  });
};

export const cardRemovedAll = () => dispatch => {
  dispatch({
    type: CARD_REMOVED_ALL,
  });
};

export const cardAdded = (key, data) => async (dispatch, getState) => {
  const {
    card: { data: cardList },
  } = getState();
  if (cardList.find(card => card.id === data.id)) return;

  dispatch(cardLoadingStatus(true));
  const card = await replaceWithPublicURL(data);

  dispatch({
    type: CARD_ADDED,
    payload: {
      key,
      data: card,
    },
  });
  dispatch(cardLoadingStatus(false));
};

export const cardChanged = (key, data) => async dispatch => {
  dispatch(cardLoadingStatus(true));
  const card = await replaceWithPublicURL(data);

  dispatch({
    type: CARD_CHANGED,
    payload: {
      key,
      data: card,
    },
  });
  dispatch(cardLoadingStatus(false));
};

export const cardRemoved = key => dispatch => {
  dispatch({
    type: CARD_REMOVED,
    payload: {
      key,
    },
  });
};

export const updateFilter = (key, value, clear) => ({
  type: UPDATE_FILTER,
  payload: {
    key,
    value,
    clear,
  },
});
