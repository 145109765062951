import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactCardFlip from 'react-card-flip';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Image from 'components/Image';
import { connect } from 'react-redux';
import { firebaseDB } from 'service/firebase';

const useStyles = makeStyles({
  card: {
    height: '100%',
    padding: 0,
    cursor: 'pointer',
  },
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  zoomBtn: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  bookmarkBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});

const configureBookmarked = (user, id) => {
  if (user && id) {
    if (user.cards && user.cards[id]) {
      firebaseDB
        .ref(`users/${user.uid}/cards/${id}`)
        .remove()
        .catch(err => {
          console.error(err);
        });
    } else {
      firebaseDB
        .ref(`users/${user.uid}/cards/${id}`)
        .set(true)
        .catch(err => {
          console.error(err);
        });
    }
  }
};

const FlashCard = React.memo(({ history, authUser, disableBookMark, id, data, onOpen }) => {
  const classes = useStyles();
  const [show, setShow] = useState('front');

  const flipCard = event => {
    event.stopPropagation();
    setShow(show === 'front' ? 'back' : 'front');
  };

  const CardButtons = (
    <React.Fragment>
      <IconButton
        onClick={event => {
          event.stopPropagation();
          onOpen(data, show);
        }}
        className={classes.zoomBtn}
      >
        <ZoomInIcon fontSize="large" />
      </IconButton>
      <IconButton
        onClick={event => {
          event.stopPropagation();
          if (!authUser || disableBookMark) history.push('/signin', { from: '/home' });
          else configureBookmarked(authUser, id);
        }}
        className={classes.bookmarkBtn}
      >
        {authUser && authUser.cards && authUser.cards[id] ? (
          <BookmarkIcon color="primary" fontSize="large" />
        ) : (
          <BookmarkBorderIcon color="inherit" fontSize="large" />
        )}
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box className={classes.root}>
      <ReactCardFlip
        isFlipped={show !== 'front'}
        flipDirection="horizontal"
        containerStyle={{ height: '100%' }}
      >
        <Card className={classes.card} onClick={flipCard}>
          <Image alt="Card Front" src={data.front_image} />
          {CardButtons}
        </Card>
        <Card className={classes.card} onClick={flipCard}>
          <Image alt="Card Back" src={data.back_image} />
          {CardButtons}
        </Card>
      </ReactCardFlip>
    </Box>
  );
});

FlashCard.defaultProps = {
  authUser: null,
  onOpen: () => {},
  disableBookMark: false,
};

FlashCard.propTypes = {
  history: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onOpen: PropTypes.func,
  authUser: PropTypes.object,
  disableBookMark: PropTypes.bool,
};

const mapStateToProps = ({ auth: { data: authUser } }) => ({
  authUser,
});

export default withRouter(connect(mapStateToProps)(FlashCard));
