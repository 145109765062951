export const MAX_ATTEMPTS = 2;
export const SECTIONS = [
  { label: 'Section A', value: 'A' },
  { label: 'Section B', value: 'B' },
  { label: 'Section C', value: 'C' },
  { label: 'Section D', value: 'D' },
];

export const PAPERS = [
  'BKM_14',
  'BKM_14.5',
  'BKM_15',
  'BKM_6',
  'BKM_7',
  'Butsic',
  'Feldblum_IRR',
  'McClenahan_Insurance_Profitability',
];

export const STARS = [
  { label: 'One', value: 1 },
  { label: 'Two', value: 2 },
  { label: 'Three', value: 3 },
];

export default {
  SECTIONS,
  PAPERS,
  STARS,
};
