import {
  UPDATE_FILTER,
  CARD_ADDED,
  CARD_ADDED_ALL,
  CARD_REMOVED_ALL,
  CARD_CHANGED,
  CARD_REMOVED,
  CARD_LOADING,
} from 'redux/actions/CardActions';

const initialState = {
  data: [],
  filter: {},
  loading: false,
};

const NotificationReducer = (state = initialState, action) => {
  const cards = [...state.data];

  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        filter: {
          ...(action.payload.clear ? initialState.filter : state.filter),
          ...(action.payload.key ? { [action.payload.key]: action.payload.value } : {}),
        },
      };

    case CARD_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      };

    case CARD_ADDED_ALL:
      return {
        ...state,
        data: action.payload.datas,
        filter: {},
      };

    case CARD_REMOVED_ALL:
      return {
        ...state,
        data: [],
        filter: {},
      };

    case CARD_ADDED:
      cards.splice(action.payload.key, 0, action.payload.data);
      return {
        ...state,
        data: cards,
      };

    case CARD_CHANGED:
      cards.splice(action.payload.key, 1, action.payload.data);
      return {
        ...state,
        data: cards,
      };

    case CARD_REMOVED:
      cards.splice(action.payload.key, 1);
      return {
        ...state,
        data: cards,
      };

    default:
      return state;
  }
};

export default NotificationReducer;
