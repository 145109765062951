import React from "react";
import Container from "@material-ui/core/Container";

const ContactUs = () => {
  return (
    <div className="landing">
      <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div>
          <h2 style={{ color: "#00CEFF" }}>Contact us</h2>
          Email: actuaryfellow@outlook.com <br />
          <br />
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
