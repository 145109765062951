import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import PriceCards from "components/PriceCards";
import LandingInfor from "components/LandingInfor";
import Footer from "components/Layout/Footer";

import { updateCurrentExam } from "redux/actions/ExamActions";
import { Container } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    height: "100%",
    padding: 0,
    cursor: "pointer",
    // border: '1px solid',
  },
  cardBtn: {
    width: "98%",
    height: "150px",
    border: "1px solid",
    borderRadius: "5px",
    margin: "10px",
    fontSize: "23px",
  },
  cardDescription: {
    textAlign: "center",
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "20px",
  },
  cardDemo: {
    margin: "auto",
    marginTop: "20px",
    width: "80%",
    height: "244px",
    padding: "20px",
    paddingTop: "50px",
  },
  cardDemoBtn: {
    marginLeft: "30px",
  },
  cardBox: {
    borderRadius: "20px",
    margin: "20px",
  },
  toolbarIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  tabs: {
    display: "flex",
  },
  tab: {
    backgroundColor: "#00CEFF",
    flex: 1,
    color: "white",
    fontSize: "20px",
  },
  tabActive: {
    backgroundColor: "white",
    flex: 1,
    border: "1px solid #00CEFF",
    color: "#00CEFF",
    fontSize: "20px",
  },
});

const ExamCards = React.memo(({ data, disableBookMark, dispatch }) => {
  const classes = useStyles();
  //   const [opendData, setOpenedData] = useState(null);
  const [currentExam, setCurrentExam] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {
    if (data && data.length > 0) {
      setCurrentExam(data[data.length - 1]);
      dispatch(updateCurrentExam(data[data.length - 1]));
    }
  }, [data]);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div style={{ background: "white" }}>
      <Paper square>
        <Tabs className="tabs" centered scrollButtons="on">
          {data.map((card) => {
            return (
              <>
                {currentExam && (
                  <Tab
                    className={
                      card?.id === currentExam.id
                        ? // ? { backgroundColor: '#8383af' }
                          // : { backgroundColor: 'white' }
                          classes.tabActive
                        : classes.tab
                    }
                    label={card.name}
                    onClick={(event) => {
                      setCurrentExam(card);
                      dispatch(updateCurrentExam(card));
                    }}
                  />
                )}
              </>
            );
          })}
        </Tabs>
      </Paper>

      <LandingInfor currentExam={currentExam} />

      <Box padding={2}>
        <Container style={{ color: "#00CEFF" }}>
          <h2>
            * All practice exams and problems are administered on emulated CBT
            setting:{" "}
            <a
              href="https://firebasestorage.googleapis.com/v0/b/exceltest-96c8c.appspot.com/o/images%2Fcbt%20demo.jpg?alt=media&token=193ecd2a-625e-441a-995c-2a8c3a94cf32"
              target="_blank"
              rel="noopener noreferrer"
            >
              Demo
            </a>
          </h2>
          <h2>
            * Online flashcard study tool with bookmarking feature:{" "}
            <a
              href="https://theactuaryfellow.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Demo
            </a>
          </h2>
          <p>
            Purchase amounts of supplemental study materials below can be used
            as credits towards the purchase of online seminar/study manual
            package
          </p>
          <p>20% out-of-pocket discount is available upon request</p>
        </Container>
        {currentExam && currentExam.bundles && (
          <Box padding={2} className={classes.cardBox}>
            <PriceCards currentExam={currentExam} isAuthorized={false} />
          </Box>
        )}
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleChange} style={{ textAlign: "right" }}>
            {expanded ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </IconButton>
        </div>
        <Collapse in={expanded}>
          <Box padding={2} className={classes.cardBox}>
            <h2 style={{ marginLeft: "70px", fontSize: "30px" }}>
              All Avaliable Products
            </h2>

            <Grid alignItems="stretch" justify="flex-start" container>
              <Grid item xs={12} md={6} lg={6}>
                <Card className={classes.cardDemo}>
                  <CardHeader title="FlashCards" />
                  <CardContent style={{ marginLeft: "30px" }}>
                    <Button
                      className={classes.cardDemoBtn}
                      color="primary"
                      variant="outlined"
                    >
                      Purchase
                    </Button>
                    <Button
                      component={RouterLink}
                      className={classes.cardDemoBtn}
                      color="primary"
                      variant="outlined"
                      to={`/home/cards`}
                    >
                      Demo
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                {currentExam &&
                  Object.keys(currentExam).includes("practices") &&
                  currentExam.practices.map((practice) => {
                    return (
                      <Card className={classes.cardDemo}>
                        <CardHeader title={practice.name} />
                        <CardContent style={{ marginLeft: "30px" }}>
                          <Button
                            className={classes.cardDemoBtn}
                            color="primary"
                            variant="outlined"
                          >
                            Purchase
                          </Button>
                          <Button
                            component={RouterLink}
                            className={classes.cardDemoBtn}
                            color="primary"
                            variant="outlined"
                            to={`/home/exam/${currentExam.id}`}
                          >
                            Demo
                          </Button>
                        </CardContent>
                      </Card>
                    );
                  })}
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>
      <Footer />
    </div>
  );
});

ExamCards.defaultProps = {
  data: [],
  disableBookMark: false,
};

ExamCards.propTypes = {
  data: PropTypes.array,
  disableBookMark: PropTypes.bool,
};

const mapStateToProps = ({ exam: { current_exam } }) => ({
  current_exam,
});

export default connect(mapStateToProps)(ExamCards);
