import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { SECTIONS, STARS } from 'config/valiables';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  paper: {
    margin: 0,
    padding: theme.spacing(6),
    position: 'relative',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  expand: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
    visibility: 'hidden',
    transition: theme.transitions.create(['visibility'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  collapse: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  visible: {
    visibility: 'visible',
  },
}));

const HorizontalBar = ({ papers, values, onChange, onClickReview, onClickGenerate }) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(true);
  const [cardCount, setCardCount] = useState(1);
  const needResetPaperFilter = !!(values.paper && !papers.includes(values.paper));

  useEffect(() => {
    if (needResetPaperFilter) {
      onChange(null, 'paper', undefined);
    }
  }, [needResetPaperFilter, onChange]);

  return (
    <Box className={classes.root}>
      <IconButton
        className={clsx(classes.expand, !visible && classes.visible)}
        onClick={() => setVisible(true)}
      >
        <ExpandMoreIcon />
      </IconButton>
      <Collapse in={visible}>
        <Paper className={clsx(classes.paper, !visible && classes.paperClose)}>
          <Grid container spacing={3}>
            <Grid container spacing={3} item xs={12} lg={6}>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="section-select-label">
                    Section
                  </InputLabel>
                  <Select
                    labelId="section-select-label"
                    id="section-select"
                    displayEmpty
                    value={values.section || ''}
                    onChange={event => onChange(event, 'section', event.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    {SECTIONS.map(section => (
                      <MenuItem key={section.value} value={section.value}>
                        {section.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="paper-select-label">
                    Paper
                  </InputLabel>
                  <Select
                    labelId="paper-select-label"
                    id="paper-select"
                    displayEmpty
                    value={needResetPaperFilter ? '' : values.paper || ''}
                    onChange={event => onChange(event, 'paper', event.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    {papers.map(paper => (
                      <MenuItem key={paper} value={paper}>
                        {paper}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel shrink id="star-select-label">
                    Star
                  </InputLabel>
                  <Select
                    labelId="star-select-label"
                    id="star-select"
                    displayEmpty
                    value={values.star || ''}
                    onChange={event => onChange(event, 'star', event.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    {STARS.map(star => (
                      <MenuItem key={star.value} value={star.value}>
                        {star.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={event => onClickReview(event)}
                >
                  Review
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={3} item xs={12} lg={6}>
              <Grid item xs={12}>
                <TextField
                  className={classes.formControl}
                  id="number-of-card-input"
                  label="Number of Card"
                  type="number"
                  value={cardCount}
                  onChange={event => setCardCount(event.target.value)}
                  InputProps={{ inputProps: { min: 1 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={event => onClickGenerate(event, cardCount)}
                >
                  Generate
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <IconButton className={classes.collapse} onClick={() => setVisible(false)}>
            <ExpandLessIcon />
          </IconButton>
        </Paper>
      </Collapse>
    </Box>
  );
};

HorizontalBar.defaultProps = {};

HorizontalBar.propTypes = {
  values: PropTypes.object.isRequired,
  papers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClickReview: PropTypes.func.isRequired,
  onClickGenerate: PropTypes.func.isRequired,
};

export default HorizontalBar;
