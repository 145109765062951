/**
 * Root Reducer
 */
import { combineReducers } from 'redux';

// Import Reducers
import card from './CardReducer';
import exam from './ExamReducer';
import auth from './AuthReducer';

// Combine all reducers into one root reducer
export default combineReducers({
  card,
  exam,
  auth,
});
