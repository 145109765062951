import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="landing">
      <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div>
          Privacy Policy
          <br />
          <br />
          At ActuaryFellow we know that you care about how your information is
          used and shared. We appreciate your trust that we will use your
          information carefully and sensibly. This Privacy Policy (“Policy”) is
          meant to help you understand what information we collect, why we
          collect it, and how you can update and manage your information. Our
          privacy practices reflect current global principles and standards for
          processing personal data.
          <br />
          <br />
          BY USING HTTPS://WWW.ACTUARYFELLOWS.COM, YOU ACCEPT THE PRACTICES
          DESCRIBED IN THIS POLICY.
          <br />
          <br />
          The ActuaryFellow (“we” or “us”) may amend this Policy at any time; we
          will provide you with notice when the changes are material. Changes to
          this Policy may affect the way we use the information collected about
          you, including personal data provided by or collected from you. Please
          visit our website to obtain current information about our Policy. If
          you do not agree with the practices described in this Policy, you must
          discontinue using www.actuaryfellow.com, any ActuaryFellow web
          presences, mobile applications and web applications operated by
          ActuaryFellow. Our Terms of Use (“Terms”), which are a part of this
          Policy, further describe the terms governing your use of this website.
          <br />
          <br />
          WHAT WE COLLECT AND HOW WE USE IT
          <br />
          <br />
          The ActuaryFellow collects information to provide you with the best
          products, services and experiences we can, and to ensure our website
          is operating efficiently. The information we collect generally falls
          into two categories: (1) personal data about you that you voluntarily
          provide to us (“personal data”), and (2) tracking information
          collected as you navigate through ActuaryFellow operated applications
          and websites (“tracking information”).
          <br />
          <br />
          AGGREGATE AND COMPUTER- RELATED INFORMATION
          <br />
          <br />
          When you use the website, we may collect tracking information such as
          your browser type, the type of operating system you use, your IP
          address, device identifiers and features, the name of your Internet
          service provider, and pages visited on the website. If you visit our
          website or other from a location-enabled device, that device will send
          us data about your location based on your device settings. We collect
          this information for aggregate reporting on website activity and for
          identifying possible sources of website abuse or malicious activity.
          For example, we may want to know how long the average user spends on
          the website or which pages or features get the most attention.
          <br />
          <br />
          When we collect this tracking information, we endeavor to remove any
          identifying elements by combining it with information about other
          individuals and/or by removing characteristics that make the
          information personally identifiable to you. ActuaryFellow reserves the
          right to share with third parties anonymous, aggregated information
          about you and other visitors to our website. We share this information
          in order to improve our website, analyze usage trends, and develop
          content and services that may be of interest to you.
          <br />
          <br />
          Likewise, ActuaryFellow may allow third party vendors to collect
          industry-standard user information (which may include data IP
          addresses and other device identifiers) through use of cookies, pixel
          tags, web beacons, technologies, and other relevant tags on our
          website for use by the ActuaryFellow to better understand the needs
          and interests of our members, for statistical and research purposes,
          and to improve content and services that may be of interest to you.
          <br />
          <br />
          SHARING YOUR DATA
          <br />
          <br />
          We may disclose your personal data when required by law, wherein we
          have a good faith belief that such action is necessary to comply with
          a current judicial proceeding, a criminal investigation, a court
          order, or legal process. We may also share your personal data if we
          believe it is necessary in order to investigate, prevent, or take
          action regarding illegal activities, suspected fraud, situations
          involving potential threats to the physical safety of any person,
          violations of the Terms of Use, or as otherwise required by law.
          <br />
          <br />
          USE OF COOKIES
          <br />
          <br />
          ActuaryFellow uses "cookies" to help remember and process the items in
          the shopping cart, personalize your online experience and compile
          aggregate data about site traffic and interactions in order to offer
          better site experiences and tools in the future. ActuaryFellow may
          also use trusted third party services, including Google Analytics that
          track this information on our behalf. Google Analytics uses
          first-party cookies to track visitor interactions as in our case,
          where they are used to collect information about how visitors use our
          site. ActuaryFellow then uses the information to compile reports and
          to help us improve our site.
          <br />
          <br />
          Cookies contain information that is transferred to your computer's
          hard drive. These cookies are used to store information, such as the
          time that the current visit occurred, whether the visitor has been to
          the site before and what site referred the visitor to the web page.
          <br />
          <br />
          Google Analytics collects information anonymously.
          <br />
          <br />
          ACCESSING AND UPDATING YOUR PERSONAL INFORMATION
          <br />
          <br />
          We aim to provide you with easy access to your personal data. If you
          need to review, change, or update your personal information, you can
          do so by sending a mail to
          <br />
          <br />
          99 Wall Street Suite#668, New York, NY 10005 or alternatively send an
          email to actuaryfellow@outlook.com.
          <br />
          <br />
          Due to production, mailing, and system timelines, it may take a few
          days to process a request to update your preferences and personal
          data.
          <br />
          <br />
          DATA SUBJECT ACCESS RIGHTS
          <br />
          <br />
          Under the GDPR, residents of the European Union and European Economic
          Area (data subjects) have additional rights. A good explanation of
          them is available on the United Kingdom’s Information Commissioner’s
          Office website. These rights include accessing, updating, or
          requesting the deletion of personal data (unless we have to keep that
          personal data for legitimate business or legal purposes).
          <br />
          <br />
          OPT OUT OF MARKETING
          <br />
          <br />
          If you do not want to receive marketing emails from us, you can opt
          out by visiting our Manage Your Email Preferences Center. A link to
          manage your marketing preferences is included in the footer of every
          ActuaryFellow marketing email.
          <br />
          <br />
          Even if you opt out of marketing emails, we may still communicate with
          you for other purposes related to your membership and/or transactions
          initiated by you, such as new or existing orders and purchases;
          governance related information and changes; for customer service
          purposes; regarding examinations; regarding your membership; and/or
          for other activities where ActuaryFellow has a legitimate business or
          legal purpose for contacting you.
          <br />
          <br />
          ADDITIONAL TERMS
          <br />
          <br />
          PRIVACY POLICY SUPPORT
          <br />
          <br />
          If you have a question about our Policy, please contact us at
          actuaryfellow@outlook.com
          <br />
          <br />
          SECURITY OF INFORMATION
          <br />
          <br />
          The website has industry standard security measures in place to
          protect the loss, misuse, and alteration of the information under our
          control. While there is no such thing as "perfect security" on the
          Internet, we will take all reasonable steps to ensure the safety of
          your personal information. We periodically review our information
          collection, storage and processing practices to protect against
          unauthorized access to systems.
          <br />
          <br />
          INTERNATIONAL USERS
          <br />
          <br />
          ActuaryFellow as is headquartered in the United States, and our
          website(s) and applications are operated, in whole or in part, from
          the United States. The personal data we collect from you will be
          processed in the United States. ActuaryFellow relies on derogations
          (see Article 49 of the GDPR) for transferring and processing your
          personal data in the United States. ActuaryFellow transfers and
          processes your data based on your consent, to perform a contract with
          you, or to fulfill a compelling, legitimate interest of ActuaryFellow
          that does not outweigh your rights. We safeguard your privacy by
          ensuring that ActuaryFellow uses your data consistent with your
          relationship with Actuary Fellow and the privacy practices described
          in this Policy.
          <br />
          <br />
          THIRD PARTY LINKS
          <br />
          <br />
          The website may also contain links to other third party websites that
          are not under our control. These websites may have their own policies
          or no policies at all, regarding privacy. You should review these
          policies before providing information to these websites. ActuaryFellow
          has no responsibility for linked websites, and provides these links
          solely for the convenience of our website visitors.
          <br />
          <br />
          CHILDREN UNDER THE AGE OF 13
          <br />
          <br />
          Our services are directed to the general public. We do not knowingly
          collect information from children under 13 years of age or have any
          reasonable grounds for believing children under the age of 13 are
          accessing our website or using our services. If we learn that we have
          inadvertently collected personal information from a child under the
          age of 13, we will delete that information as quickly as possible. If
          you believe that we might have any information from a child under the
          age of 13, please contact us at actuaryfellow@outlook.com, by calling
          us at +1. 920-350-3228, or writing to us at Customer Service Dept., 99
          Wall Street Suite#668, New York, NY 10005.
          <br />
          <br />
          TERMS OF USE, NOTICES, AND REVISIONS
          <br />
          <br />
          If you choose to visit and/or use the website or any ActuaryFellow
          operated application, your use, visit, and any dispute over privacy is
          subject to this Policy and our Terms of Use,. If you have any concern
          about privacy on the website, please contact us with a thorough
          description, and we will try to resolve it. Our business changes
          constantly, and this Policy, the Terms of Use, and the website will
          continue to change; however, you will be notified before any material
          changes to our Policy take effect. You should check the website
          frequently to see recent changes.
        </div>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
