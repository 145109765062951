import { getToday } from 'utils/getToday';

export const getFiltered = (data, filter) =>
  data.filter(obj => {
    const keys = Object.keys(filter);
    return !keys.some(cur => filter[cur] && obj[cur] !== filter[cur]);
  });

export const purchaseFiltered = data => {
  const today = getToday();
  var purchase_list = [];
  if (data)
    data.forEach(obj => {
      if (obj.date == 'free') {
        purchase_list.push(obj.id);
      } else {
        const t_m = parseInt(getToday().split('/')[0]);
        const t_y = getToday().split('/')[2];
        const p_m = parseInt(obj.date.split('/')[0]);
        const p_y = obj.date.split('/')[2];
        if (t_y === p_y && ((t_m < 7 && p_m < 7) || (t_m >= 7 && p_m >= 7))) {
          purchase_list.push(obj.id);
        }
      }
    });
  return purchase_list;
};
export default { getFiltered, purchaseFiltered };
