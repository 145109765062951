import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Button } from "@material-ui/core";

import FlagIcon from "@material-ui/icons/Flag";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function FlagButton({ pagesData, currentPage, flagAPage }) {
  const classes = useStyles();

  const openCalculator = async () => {
    window.open(
      "https://wsr.pearsonvue.com/testtaker/common/StartTestLaunch.htm?clientCode=CAS&seriesCode=Spreadsheet_SAMPLE&languageCode=ENU",
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=300,height=850"
    );
  };

  return (
    <AppBar position="static" color="inherit">
      <Toolbar variant="dense" className={classes.root}>
        <Button color="inherit" onClick={() => openCalculator()}>
          Calculator
        </Button>
        <Button
          color={
            pagesData.length && pagesData[currentPage].flag
              ? "secondary"
              : "inherit"
          }
          startIcon={<FlagIcon />}
          onClick={flagAPage}
        >
          {pagesData.length && pagesData[currentPage].flag
            ? "Flagged"
            : "Flag for Review"}
        </Button>
      </Toolbar>
    </AppBar>
  );
}
