import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";

export default function Announcement({ data }) {
  const useStyles = makeStyles({
    title: {
      marginBottom: "5px",
    },
    subTitle: {
      marginBottom: "0",
    },
    cardDemo: {
      margin: "auto",
      marginTop: "20px",
      width: "80%",
      padding: "10px 20px",
    },
  });
  const classes = useStyles();
  const [list, setList] = useState(null);

  useEffect(() => {
    async function getAnnouncement() {
      let res = await fetch(
        "https://testexcel-41d92-default-rtdb.firebaseio.com/" +
          data.id +
          ".json"
      );
      res = await res.json();
      setList(res);
    }
    getAnnouncement();
  }, [data]);

  return (
    <Box>
      <Card className={classes.cardDemo}>
        <h2 className={classes.title}>
          Go to{" "}
          <a
            href="https://forum.actuaryfellow.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Student Forum
          </a>
        </h2>
        {list?.length && <p className={classes.subTitle}>Updates</p>}
        {list?.length &&
          list.map((infor, index) => (
            <div key={index}>
              <li>
                {infor.date}: {infor.text}{" "}
                {infor.link && (
                  <a
                    href={infor.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    (Link)
                  </a>
                )}
              </li>
            </div>
          ))}
      </Card>
    </Box>
  );
}
