import {
  AUTH_CHANGED,
  AUTH_LOADING,
  USER_BOOKMARK_CHANGED,
  USER_SUBSCRIPTION_CHANGED,
} from 'redux/actions/AuthActions';

const initialState = {
  data: null,
  loading: true,
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      };

    case AUTH_CHANGED:
      return {
        ...state,
        data: action.payload.data,
      };

    case USER_BOOKMARK_CHANGED:
      if (!state.data) break;
      return {
        ...state,
        data: {
          ...state.data,
          cards: action.payload.data,
        },
      };

    case USER_SUBSCRIPTION_CHANGED:
      if (!state.data) break;
      return {
        ...state,
        data: {
          ...state.data,
          subscription: action.payload.data,
        },
      };

    default:
      return state;
  }

  return state;
};

export default NotificationReducer;
