import React, { useEffect } from 'react';
import { useRouteMatch, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firebaseDB } from 'service/firebase';
import FlashCards from 'components/FlashCards';
import { CardsLayout } from 'components/Layout';
import {
  cardAdded,
  cardAddedAll,
  cardRemovedAll,
  cardChanged,
  cardLoadingStatus,
  cardRemoved,
  updateFilter,
} from 'redux/actions/CardActions';
import { getFiltered } from 'utils/filter';
import { updateCurrentExam } from 'redux/actions/ExamActions';
import sampleData from 'config/sample.json';

const cardRef = firebaseDB.ref('cards');

const Cards = ({
  filter,
  loading,
  bookmarkedCards,
  cards,
  userType,
  examData,
  match,
  dispatch,
  location,
}) => {
  const { path } = useRouteMatch();
  const sectionCards = filter.section ? getFiltered(cards, { section: filter.section }) : cards;
  const papers = sectionCards
    .reduce((acc, cur) => (acc.includes(cur.paper) ? acc : [...acc, cur.paper]), [])
    .sort((a, b) => a.localeCompare(b));

  const handleFilter = (key, value, clear = false) => {
    dispatch(updateFilter(key, value, clear));
  };

  useEffect(() => {
    dispatch(cardLoadingStatus(true));
    dispatch(cardRemovedAll());
    cardRef.off();

    if (userType === 'pro') {
      // Pull from a dataset called 'cards' from Firebase
      cardRef.once(
        'value',
        async snapshot => {
          try {
            await dispatch(cardAddedAll(snapshot.val()));
          } catch (err) {
            console.error(err);
          }
          cardRef.on('child_added', snap => {
            dispatch(cardAdded(snap.key, snap.val()));
          });
          cardRef.on('child_changed', snap => {
            dispatch(cardChanged(snap.key, snap.val()));
          });
          cardRef.on('child_removed', snap => {
            dispatch(cardRemoved(snap.key));
          });
          dispatch(cardLoadingStatus(false));
        },
        err => {
          console.error(err);
        },
      );
    } else {
      dispatch(cardLoadingStatus(false));
    }

    return () => {
      dispatch(cardRemovedAll());
      cardRef.off();
    };
  }, [userType, dispatch]);

  useEffect(() => {
    dispatch(updateFilter(null, null, true));
  }, [dispatch, location]);

  useEffect(() => {
    if (match.params.exam_id != 'bookmarks') {
      const currentExam = examData ? examData.find(el => el.id == match.params.exam_id) : {};
      console.log('------', examData);
      console.log('----====', currentExam);
      dispatch(updateCurrentExam(currentExam));
    }
  }, [dispatch, location, examData]);

  return (
    <CardsLayout
      basePath="/home"
      loading={loading}
      papers={papers}
      filter={filter}
      onChangeFilter={handleFilter}
    >
      <Switch>
        <Route path={`/home/cards/bookmarks`}>
          <FlashCards data={getFiltered(bookmarkedCards, filter)} />
        </Route>
        <Route exact path={path}>
          <FlashCards data={getFiltered(sectionCards, filter)} />
        </Route>
        <Redirect to={path} />
      </Switch>
    </CardsLayout>
  );
};

Cards.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  cards: PropTypes.array.isRequired,
  bookmarkedCards: PropTypes.array.isRequired,
  userType: PropTypes.oneOf(['pro', 'free']).isRequired,
};

const mapStateToProps = ({
  card: { filter, data, loading },
  auth: { data: userData },
  exam: { data: examData, purchase, current_exam },
}) => {
  const current_purchase = current_exam ? purchase.find(el => el.exam_id == current_exam.id) : {};
  // const userType =
  //   userData && userData.subscription && userData.subscription.type === 'pro' ? 'pro' : 'free';
  console.log("===", current_exam, purchase)
  const userType = current_exam && current_purchase.practice_id.includes('flashcard') ? 'pro' : 'free';
  const userCards = userData && userData.cards ? userData.cards : {};
  const examCards = current_exam
    ? current_exam.cards.map(cardId => [...data].find(card => card.id === cardId)).filter(card => card)
    : [];
  const bookmarkedCards = Object.keys(userCards)
    .map(cardId => [...examCards, ...sampleData].find(card => card.id === cardId))
    .filter(card => card);
  console.log('00000', bookmarkedCards);

  return {
    filter,
    cards: userType === 'pro' ? examCards : sampleData,
    loading,
    bookmarkedCards,
    userType,
    examData,
  };
};

export default withRouter(connect(mapStateToProps)(Cards));
