import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import { DRAWER_WIDTH } from "config/styles";
import { firebaseAuth, firebaseDB } from "service/firebase";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    textDecoration: "none !important",
    width: "90px",
    marginTop: "5px",
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  active: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  activelink: {
    color: theme.palette.primary.main,
  },
}));

export const Header = withRouter(({ location }) => {
  const classes = useStyles();

  return (
    <AppBar color="default" position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.title}>
          <Link component={RouterLink} variant="h6" color="textPrimary" to="/">
            <img className={classes.logo} src="logo.png" alt="logo" />
          </Link>
        </Box>
        <nav>
          <Link
            component={RouterLink}
            variant="button"
            color="textPrimary"
            to="/home/pricing"
            className={clsx(
              classes.link,
              location.pathname === `/pricing` && classes.activelink
            )}
          >
            Pricing
          </Link>
        </nav>
        <Button
          component={RouterLink}
          color="primary"
          variant="outlined"
          to={{ pathname: "/signin", state: { from: "/home" } }}
          className={classes.link}
        >
          Sign in
        </Button>
      </Toolbar>
    </AppBar>
  );
});

Header.propTypes = {
  location: PropTypes.object,
};

export const MainHeader = withRouter(
  ({ basePath, drawerOpened, onDrawerOpen, history, location, cardLayout }) => {
    const classes = useStyles();
    const handleSignOut = () => {
      const authUserId = firebaseAuth.currentUser
        ? firebaseAuth.currentUser.uid
        : null;
      if (authUserId) {
        const userCardsRef = firebaseDB.ref(`users/${authUserId}/cards`);
        if (userCardsRef) userCardsRef.off();
        const userSubscriptionRef = firebaseDB.ref(
          `subscriptions/${authUserId}`
        );
        userSubscriptionRef.off();
      }
      firebaseAuth
        .signOut()
        .then(() => {
          // Sign-out successful.
          history.replace("/landing");
        })
        .catch((error) => {
          // An error happened.
          console.error(error);
        });
    };

    return (
      <AppBar
        color="default"
        position="absolute"
        className={clsx(
          classes.appBar,
          onDrawerOpen && drawerOpened && classes.appBarShift
        )}
      >
        <Toolbar className={classes.toolbar}>
          {onDrawerOpen && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={onDrawerOpen}
              className={clsx(
                classes.menuButton,
                drawerOpened && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box className={classes.title}>
            <Typography
              component={RouterLink}
              variant="h6"
              color="textPrimary"
              to={basePath}
              className={classes.logo}
            >
              FlashCards App
            </Typography>
          </Box>
          <nav>
            <Button
              component={RouterLink}
              variant="outlined"
              color="primary"
              to={`${basePath}`}
              className={clsx(
                classes.link,
                location.pathname === `${basePath}/cards` && classes.active
              )}
            >
              Home
            </Button>
            {cardLayout && (
              <Button
                component={RouterLink}
                variant="outlined"
                color="primary"
                to={`${basePath}/cards/bookmarks`}
                className={clsx(
                  classes.link,
                  location.pathname === `${basePath}/cards/bookmarks` &&
                    classes.active
                )}
              >
                BookMarks
              </Button>
            )}

            <Button
              component={RouterLink}
              variant="outlined"
              color="primary"
              to={`${basePath}/support`}
              className={clsx(
                classes.link,
                location.pathname === `${basePath}/support` && classes.active
              )}
            >
              Support
            </Button>
            <Button
              component={RouterLink}
              variant="outlined"
              color="primary"
              to={`${basePath}/pricing`}
              className={clsx(
                classes.link,
                location.pathname === `${basePath}/pricing` && classes.active
              )}
            >
              Pricing
            </Button>
            <Button
              component={RouterLink}
              variant="outlined"
              color="primary"
              to={`${basePath}/account`}
              className={clsx(
                classes.link,
                location.pathname === `${basePath}/account` && classes.active
              )}
            >
              Account
            </Button>
          </nav>
          <Button
            color="secondary"
            variant="outlined"
            className={classes.link}
            onClick={handleSignOut}
          >
            Sign out
          </Button>
        </Toolbar>
      </AppBar>
    );
  }
);

MainHeader.defaultProps = {
  drawerOpened: true,
  onDrawerOpen: null,
  cardLayout: true,
};

MainHeader.propTypes = {
  drawerOpened: PropTypes.bool,
  basePath: PropTypes.string.isRequired,
  onDrawerOpen: PropTypes.func,
  cardLayout: PropTypes.bool,
};
