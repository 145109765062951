import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Layout } from "components/Layout";
import PrivateRoute from "components/PrivateRoute";
import { Header } from "components/Layout/Header";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import HomePage from "pages/HomePage";
import Landing from "pages/Landing";
import Pricing from "pages/Pricing";
import Cards from "pages/Cards";
import HomeIn from "pages/HomeIn";
import PracticeExam from "pages/PracticeExam";
import Account from "pages/Account";
import ExamSheet from "./components/ExamSheet";
import Subscribe from "pages/Subscribe";
import Charities from "pages/Charities";
import TermsOfService from "pages/TermsOfService";
import PrivacyPolicy from "pages/PrivacyPolicy";
import ContactUs from "pages/ContactUs";
import AboutUs from "pages/AboutUs";
import { firebaseAuth, firebaseDB } from "service/firebase";
import {
  authChanged,
  authLoadingStatus,
  userBookMarkChanged,
  userSubscriptionChanged,
} from "redux/actions/AuthActions";

import {
  examAdded,
  examAddedAll,
  examRemovedAll,
  examChanged,
  examLoadingStatus,
  examRemoved,
  purchaseAddedAll,
  userPurchaseChanged,
  attemptAddedAll,
  userAttemptChanged,
  updateFilter,
} from "redux/actions/ExamActions";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100vh",
    width: "100vw",
  },
  center: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  text: {
    paddingTop: theme.spacing(1),
  },
}));

const Home = () => {
  const { path } = useRouteMatch();

  return (
    <Layout isAuthorized basePath={path}>
      <Switch>
        <Route
          path={`${path}/subscribe/:exam_id/:bundle_id`}
          component={Subscribe}
        />
        <Route path={`${path}/support`} render={() => {}} />
        <Route path={`${path}/account`} component={Account} />
        {/* <Redirect to={`${path}/cards`} /> */}
        <Redirect to={`${path}/main`} />
      </Switch>
    </Layout>
  );
};

const App = ({ dispatch, loading, authUserId, exams, location }) => {
  const classes = useStyles();

  useEffect(() => {
    dispatch(authLoadingStatus(true));
    firebaseAuth.onAuthStateChanged((user) => {
      dispatch(authChanged(user)).then(() => {
        dispatch(authLoadingStatus(false));
      });
    });
  }, [dispatch]);

  // Loading User information and subscription information
  useEffect(() => {
    if (authUserId) {
      const userCardsRef = firebaseDB.ref(`users/${authUserId}/cards`);
      userCardsRef.on("value", (snapshot) => {
        dispatch(userBookMarkChanged(snapshot.val()));
      });
      const userSubscriptionRef = firebaseDB.ref(`subscriptions/${authUserId}`);
      userSubscriptionRef.on("value", (snapshot) => {
        dispatch(userSubscriptionChanged(snapshot.val()));
      });
    }

    return () => {
      if (authUserId) {
        const userCardsRef = firebaseDB.ref(`users/${authUserId}/cards`);
        userCardsRef.off();
        const userSubscriptionRef = firebaseDB.ref(
          `subscriptions/${authUserId}`
        );
        userSubscriptionRef.off();
      }
    };
  }, [authUserId, dispatch]);

  // loading exams from firebase
  useEffect(() => {
    const examRef = firebaseDB.ref("exams");
    dispatch(examLoadingStatus(true));
    dispatch(examRemovedAll());
    examRef.off();

    // Pull from a dataset called 'exams' from Firebase
    examRef.once(
      "value",
      async (snapshot) => {
        try {
          await dispatch(examAddedAll(snapshot.val()));
        } catch (err) {
          console.error(err);
        }
        examRef.on("child_added", (snap) => {
          dispatch(examAdded(snap.key, snap.val()));
        });
        examRef.on("child_changed", (snap) => {
          dispatch(examChanged(snap.key, snap.val()));
        });
        examRef.on("child_removed", (snap) => {
          dispatch(examRemoved(snap.key));
        });
        dispatch(examLoadingStatus(false));
      },
      (err) => {
        console.error(err);
      }
    );

    return () => {
      dispatch(examRemovedAll());
      examRef.off();
    };
  }, [authUserId, dispatch]);

  useEffect(() => {
    if (authUserId) {
      const purchaseRef = firebaseDB.ref(`purchase/${authUserId}`);
      purchaseRef.once("value", (snapshot) => {
        if (snapshot.exists()) {
          // const initPurchase = exams.map(exam => {
          //   var purchaseItem = {};
          //   purchaseItem['exam_id'] = exam.id;
          //   purchaseItem['practice_id'] = ['demo'];
          //   purchaseItem['purchase_date'] = [{ date: 'free', id: 'demo' }];
          //   const free_bundles = exam.bundles.filter(el => (el.price === 0));
          //   free_bundles.forEach(bundle => {
          //   })
          //   return purchaseItem;
          // });
          // purchaseRef.set(initPurchase);
          // dispatch(userPurchaseChanged(initPurchase));
        } else {
          if (exams.length > 0) {
            const initPurchase = exams.map((exam) => {
              var purchaseItem = {};
              purchaseItem["exam_id"] = exam.id;
              purchaseItem["practice_id"] = ["demo"];
              purchaseItem["purchase_date"] = [{ date: "free", id: "demo" }];
              if (exam?.bundles) {
                const free_bundles = exam.bundles.filter(
                  (el) => el.price === 0
                );
                free_bundles.forEach((bundle) => {
                  bundle.practices.forEach((practice_id) => {
                    purchaseItem["practice_id"].push(practice_id);
                    purchaseItem["purchase_date"].push({
                      date: "free",
                      id: practice_id,
                    });
                  });
                });
              }

              return purchaseItem;
            });
            purchaseRef.set(initPurchase);
            dispatch(userPurchaseChanged(initPurchase));
          }
        }
      });
      const attemptRef = firebaseDB.ref(`attempts/${authUserId}`);
      attemptRef.once("value", (snapshot) => {
        if (snapshot.exists()) {
        } else {
          if (exams.length > 0) {
            const initAttempts = exams.map((exam) => {
              var attemptItem = {};
              attemptItem["exam_id"] = exam.id;
              attemptItem["practice_id"] = "demo";
              attemptItem["total_attempt"] = 0;
              return attemptItem;
            });
            attemptRef.set(initAttempts);
            dispatch(attemptAddedAll(initAttempts));
          }
        }
      });
    }
  }, [authUserId, exams, dispatch]);

  // loading purchase information from firebase
  useEffect(() => {
    if (authUserId) {
      const userPurchasenRef = firebaseDB.ref(`purchase/${authUserId}`);
      userPurchasenRef.once("value", (snapshot) => {
        dispatch(purchaseAddedAll(snapshot.val()));
      });
      userPurchasenRef.on("value", (snapshot) => {
        dispatch(userPurchaseChanged(snapshot.val()));
      });
    }

    return () => {
      if (authUserId) {
        const userPurchasenRef = firebaseDB.ref(`purchase/${authUserId}`);
        // userPurchasenRef.off();
      }
    };
  }, [authUserId, dispatch]);

  // loading attemps from firebase
  useEffect(() => {
    if (authUserId) {
      const userAttemptRef = firebaseDB.ref(`attempts/${authUserId}`);
      userAttemptRef.once("value", (snapshot) => {
        dispatch(attemptAddedAll(snapshot.val()));
      });
      userAttemptRef.on("value", (snapshot) => {
        dispatch(userAttemptChanged(snapshot.val()));
      });
    }

    return () => {
      if (authUserId) {
        const userAttemptRef = firebaseDB.ref(`attempts/${authUserId}`);
        userAttemptRef.off();
      }
    };
  }, [authUserId, dispatch]);

  useEffect(() => {
    dispatch(updateFilter(null, null, true));
  }, [dispatch, location]);

  if (loading)
    return (
      <Container className={classes.root}>
        <Box className={classes.center}>
          <CircularProgress />
          <Typography
            className={classes.text}
            as="div"
            variant="subtitle2"
            color="textPrimary"
          >
            Loading...
          </Typography>
        </Box>
      </Container>
    );

  return (
    <Switch>
      <Route exact path="/landing">
        <Header />
        <div style={{ paddingTop: "4rem" }}></div>
        <HomePage />
      </Route>
      <Route exact path="/signin" component={SignIn} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/test" component={ExamSheet} />
      <PrivateRoute path={`/home/pricing`} component={Pricing} />
      <PrivateRoute path="/home/cards/:exam_id" component={Cards} />
      <PrivateRoute path="/home/main" component={HomeIn} />
      <PrivateRoute path="/home/exam/:exam_id" component={PracticeExam} />
      <PrivateRoute
        path="/home/sheet/:examid/:practiceid/:type"
        component={ExamSheet}
      />
      <PrivateRoute path="/home" component={Home} />
      <Route path="/">
        {authUserId ? (
          <Redirect to="/home/main" />
        ) : (
          <Layout isAuthorized={authUserId ? true : false}>
            <Switch>
              {/* <Route exact path="/" component={Landing} /> */}
              <Route exact path="/" component={HomePage} />
              <Route exact path="/termsofservice" component={TermsOfService} />
              <Route exact path="/privacypolicy" component={PrivacyPolicy} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/aboutus" component={AboutUs} />
              <Route exact path="/charities" component={Charities} />
              {/* <Route path="/pricing" component={Pricing} /> */}
              <Redirect to="/" />
            </Switch>
          </Layout>
        )}
      </Route>
    </Switch>
  );
};

App.defaultProps = {
  authUserId: null,
};

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  authUserId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  auth: { loading, data },
  exam: { data: exams, purchase },
}) => ({
  loading,
  authUserId: data && data.uid ? data.uid : null,
  exams,
  purchase,
});

export default connect(mapStateToProps)(App);
