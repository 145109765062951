import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { PAPERS } from "config/valiables";

import SideBar from "./SideBar";
import { MainSideBar } from "./SideBar";
import { Header, MainHeader } from "./Header";
import HorizontalBar from "./HoriZontalBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    overflow: "auto",
    height: "100vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  mainBox: {
    flexGrow: 1,
    overflow: "auto",
    position: "relative",
  },
}));

export const Layout = ({ children, basePath, isAuthorized }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isAuthorized ? <MainHeader basePath={basePath} /> : <Header />}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.mainBox}>{children}</div>
      </main>
    </div>
  );
};

Layout.defaultProps = {
  children: null,
  basePath: "/",
  isAuthorized: false,
};

Layout.propTypes = {
  children: PropTypes.element,
  basePath: PropTypes.string,
  isAuthorized: PropTypes.bool,
};

export const CardsLayout = ({
  basePath,
  children,
  papers,
  filter,
  onChangeFilter,
  onGenerate,
  onReview,
  loading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <MainHeader
        basePath={basePath}
        drawerOpened={open}
        onDrawerOpen={handleDrawerOpen}
        cardLayout={true}
      />
      <SideBar
        open={open}
        selected={filter.section}
        onClose={handleDrawerClose}
        onSelect={(e, section) => {
          onChangeFilter("section", section, true);
        }}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.mainBox}>
          <LinearProgress hidden={!loading} />
          <HorizontalBar
            papers={papers}
            values={filter}
            onClickReview={onReview}
            onClickGenerate={onGenerate}
            onChange={(event, key, value) => {
              onChangeFilter(key, value);
            }}
          />
          {children}
        </div>
      </main>
    </div>
  );
};

CardsLayout.defaultProps = {
  loading: false,
  children: null,
  papers: PAPERS,
  basePath: "/",
  filter: {},
  onChangeFilter: () => {},
  onGenerate: () => {},
  onReview: () => {},
};

CardsLayout.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any,
  papers: PropTypes.array,
  basePath: PropTypes.string,
  filter: PropTypes.object,
  onChangeFilter: PropTypes.func,
  onGenerate: PropTypes.func,
  onReview: PropTypes.func,
};

export const MainLayout = ({
  basePath,
  children,
  onSelect,
  selected,
  loading,
  exams,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <MainHeader
        basePath={basePath}
        drawerOpened={open}
        onDrawerOpen={handleDrawerOpen}
        cardLayout={false}
      />
      <MainSideBar
        open={open}
        selected={selected.id}
        onClose={handleDrawerClose}
        onSelect={(e, exam) => {
          onSelect(exam);
        }}
        exams={exams}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <div className={classes.mainBox}>
          <LinearProgress hidden={!loading} />
          {children}
        </div>
      </main>
    </div>
  );
};

MainLayout.defaultProps = {
  loading: false,
  children: null,
  papers: PAPERS,
  basePath: "/",
  selected: {},
  onSelect: () => {},
};

MainLayout.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any,
  basePath: PropTypes.string,
  selected: PropTypes.object,
  onSelect: PropTypes.func,
};
