import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FlashCard from 'components/FlashCard';

const FlashCards = React.memo(({ data, disableBookMark }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [opendData, setOpenedData] = useState(null);

  const images = opendData ? [opendData.front_image, opendData.back_image] : [];

  return (
    <Box padding={4}>
      {opendData && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setOpenedData(null)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
      <Grid alignItems="stretch" justify="flex-start" container spacing={4}>
        {data.map(card => (
          <Grid key={card.id} item xs={12} md={6} lg={4}>
            <FlashCard
              id={card.id}
              data={card}
              disableBookMark={disableBookMark}
              onOpen={(d, c) => {
                setOpenedData(d);
                setPhotoIndex(c === 'front' ? 0 : 1);
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

FlashCards.defaultProps = {
  data: [],
  disableBookMark: false,
};

FlashCards.propTypes = {
  data: PropTypes.array,
  disableBookMark: PropTypes.bool,
};

export default FlashCards;
