import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { firebaseAuth } from 'service/firebase';

const PrivateRoute = ({ component: Component, render, children, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const user = firebaseAuth.currentUser;
      if (user) {
        if (children) return children;
        if (Component) return <Component authUser={user} {...props} />;
        if (render) return render();
      }
      // eslint-disable-next-line react/prop-types
      return <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />;
    }}
  />
);

PrivateRoute.defaultProps = {
  children: null,
  component: null,
  render: null,
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
  component: PropTypes.any,
  render: PropTypes.any,
};

export default PrivateRoute;
