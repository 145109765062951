import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import Modal from 'react-modal';
import { PRICE_DESCRIPTION_FONT_SIZE } from 'config/styles';
import { purchaseFiltered } from 'utils/filter';
import { SportsRugbySharp } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  list: ({ height }) => ({
    height,
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }),
  link: {
    margin: theme.spacing(1, 1.5),
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  modal: {
    // '&.ReactModal__Content--after-open' : {
    //   width                 : '50%',
    //   height                : '50%',
    //   margin                : 'auto',
    //   backgroundColor       : 'white'
    // },
    // '&.ReactModal__Overlay' : {
    //   backgroundColor       : 'rgba(0, 0, 0, 0.75) !important'
    // }
  },
}));

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const LINE_HEIGHT = 1.75;
Modal.setAppElement('#root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.75)';

const PriceCards = withRouter(({ location, current_exam, purchase, isAuthorized }) => {
  const state = { from: location.pathname };
  var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [pricePath, setPricePath] = React.useState();
  let tiers = [];
  if (current_exam?.bundles) {
    if (isAuthorized) {
      console.log(purchase);
      if (purchase && purchase.length > 0) {
        const c_exam_purchase = purchase.find(item => item.exam_id == current_exam.id);
        tiers = current_exam.bundles.map((bundle, index) => {
          let tier = {};
          let bundle_purchase_status = true;
          tier['description'] = bundle.practices.map(practice_id => {
            if (!purchaseFiltered(c_exam_purchase.purchase_date).includes(practice_id)) {
              bundle_purchase_status = false;
            }
            if (practice_id == 'flashcard') {
              return 'Flashcards';
            }
            const practice = current_exam.practices.find(practice => practice.id == practice_id);
            return practice.name;
          });
          tier['price'] = '$' + bundle.price.toString();
          tier['title'] = bundle.price == 0 ? 'Free' : 'Practice Exam Bundle' + index.toString();
          tier['path'] = (bundle.price == 0 || bundle_purchase_status)
            ? `/home/exam/${current_exam.id}`
            : `/home/subscribe/${current_exam.id}/${bundle.id}`;
          tier['buttonText'] = (bundle.price == 0 || bundle_purchase_status) ? 'Start' : 'Purchase';
          tier['buttonVariant'] = (bundle.price == 0 || bundle_purchase_status) ? 'outlined' : 'contained';
          tier['subheader'] = bundle?.subheader ? bundle.subheader : '';
          return tier;
        });
      }
    } else {
      tiers = current_exam.bundles.map((bundle, index) => {
        let tier = {};
        tier['title'] = bundle.price == 0 ? 'Free' : 'Practice Exam Bundle' + index.toString();
        tier['buttonText'] = bundle.price == 0 ? 'Sign up for free' : 'Purchase';
        tier['buttonVariant'] = bundle.price == 0 ? 'outlined' : 'contained';
        tier['path'] = bundle.price == 0 ? `/home/exam/${current_exam.id}` : `/home/pricing`;
        tier['description'] = bundle.practices.map(practice_id => {
          if (practice_id == 'flashcard') {
            return 'Flashcards';
          }
          const practice = current_exam.practices.find(practice => practice.id == practice_id);
          return practice.name;
        });
        tier['price'] = '$' + bundle.price.toString();
        tier['subheader'] = bundle?.subheader ? bundle.subheader : '';
        return tier;
      });
    }
  }
  const height = tiers.reduce((acc, cur) => {
    const itemHeight = cur.description.length * PRICE_DESCRIPTION_FONT_SIZE * LINE_HEIGHT + 20;
    return itemHeight > acc ? itemHeight : acc;
  }, 0);

  const classes = useStyles({ height });

  const openModal = path => {
    // const path = ""
    setPricePath(path);
    setIsOpen(true);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Container style={{ marginTop: '30px' }} component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => {
            return (
              <Grid item key={tier.title} xs={12} sm={6} md={3}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Free' ? null : <StarIcon color="primary" />}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    {tier.price !== undefined && tier.price !== null ? (
                      <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h3" color="textPrimary">
                          {tier.price}
                        </Typography>
                        <Typography variant="h6" color="textSecondary"></Typography>
                      </div>
                    ) : (
                      <div className={classes.cardPricing} style={{ alignItems: 'center' }}>
                        <Typography component="h4" variant="h5" color="textSecondary">
                          Pending
                        </Typography>
                      </div>
                    )}
                    <ul className={classes.list}>
                      {tier.description.map(line => (
                        <Typography
                          key={line}
                          component="li"
                          variant="subtitle1"
                          align="center"
                          noWrap
                          style={{ fontSize: PRICE_DESCRIPTION_FONT_SIZE, lineHeight: LINE_HEIGHT }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    {isAuthorized ? (
                      <Button
                        fullWidth
                        component={RouterLink}
                        variant={tier.buttonVariant}
                        color="primary"
                        to={{ pathname: tier.path, state }}
                        // onClick={()=>openModal(tier.path)}
                        className={classes.link}
                      >
                        {tier.buttonText}
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        component={RouterLink}
                        variant={tier.buttonVariant}
                        color="primary"
                        // to={{ pathname: tier.path, state}}
                        onClick={() => openModal(tier.path)}
                        className={classes.link}
                      >
                        {tier.buttonText}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
            // className={classes.modal}
          >
            <h1 ref={_subtitle => (subtitle = _subtitle)}>Purchase Exam</h1>
            <h3>You should sign in or create an account first to purchase exams.</h3>
            <h3>Would you like to sign in or create an account?</h3>
            <form>
              <Button
                component={RouterLink}
                className={classes.link}
                color="primary"
                variant="contained"
                // onClick={closeModal}
                to={{ pathname: pricePath, state }}
              >
                Yes
              </Button>
              <Button className={classes.link} color="primary" variant="outlined" onClick={closeModal}>
                No
              </Button>
            </form>
          </Modal>
        </div>
      </Container>
    </React.Fragment>
  );
});

PriceCards.propTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  exam: { filter, data, loading, purchase, current_exam },
  auth: { data: userData },
}) => {
  // const authUserId = userData && userData.uid ? userData.uid : null;
  // const userType =
  //   userData && userData.subscription && userData.subscription.type === 'pro' ? 'pro' : 'free';
  const current_purchase = current_exam ? purchase.find(el => el.exam_id == current_exam.id) : {};
  const userType =
    current_exam &&
    current_purchase?.practice_id &&
    purchaseFiltered(current_purchase.purchase_date).includes('flashcard')
      ? 'pro'
      : 'free';
  return { filter, purchase, loading, userType, current_exam };
};

export default withRouter(connect(mapStateToProps)(PriceCards));
