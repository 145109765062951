import React, { useEffect, useState } from "react";
import {
  useRouteMatch,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firebaseDB } from "service/firebase";
import AttemptExam from "components/AttemptExam";
import { MainLayout } from "components/Layout";

import sampleData from "config/sample.json";

import {
  purchaseAddedAll,
  userPurchaseChanged,
  attemptAddedAll,
  userAttemptChanged,
} from "redux/actions/ExamActions";

const PracticeExam = ({
  loading,
  exams,
  purchase,
  attempts,
  userType,
  authUserId,
  match,
  dispatch,
}) => {
  const { path } = useRouteMatch();
  const [selectedExam, setSelectedExam] = useState();
  const [selectedPurchase, setSelectedPurchase] = useState();
  const [selectedAttempt, setSelectedAttempt] = useState();
  const [purchasedPractices, setPurchasedPractices] = useState();

  const handleChange = (exam) => {
    setSelectedExam(exam);
    const currentAttempt = attempts.find(
      (el) =>
        el?.exam_id == selectedPurchase.exam_id && el.practice_id == exam.id
    );
    setSelectedAttempt(currentAttempt);
  };

  // loading purchase information from firebase
  useEffect(() => {
    if (!purchase) {
      const userPurchasenRef = firebaseDB.ref(`purchase/${authUserId}`);
      userPurchasenRef.once("value", (snapshot) => {
        dispatch(purchaseAddedAll(snapshot.val()));
      });
      userPurchasenRef.on("value", (snapshot) => {
        dispatch(userPurchaseChanged(snapshot.val()));
      });
    }

    return () => {
      if (!purchase) {
        const userPurchasenRef = firebaseDB.ref(`purchase/${authUserId}`);
        userPurchasenRef.off();
      }
    };
  });

  // loading attemps from firebase
  useEffect(() => {
    if (!attempts) {
      const userAttemptRef = firebaseDB.ref(`attempts/${authUserId}`);
      userAttemptRef.once("value", (snapshot) => {
        dispatch(attemptAddedAll(snapshot.val()));
      });
      userAttemptRef.on("value", (snapshot) => {
        dispatch(userAttemptChanged(snapshot.val()));
      });
    }

    return () => {
      if (!attempts) {
        const userAttemptRef = firebaseDB.ref(`attempts/${authUserId}`);
        userAttemptRef.off();
      }
    };
  });

  useEffect(() => {
    if (attempts && selectedAttempt) {
      const currentAttempt = attempts.find(
        (el) =>
          el.exam_id == selectedPurchase.exam_id &&
          el.practice_id == selectedAttempt.practice_id
      );
      setSelectedAttempt(currentAttempt);
    }
    if (attempts && selectedPurchase && selectedExam && !selectedAttempt) {
      const currentAttempt = attempts.find(
        (el) =>
          el.exam_id == selectedPurchase.exam_id &&
          el.practice_id == selectedExam.id
      );
      if (currentAttempt) setSelectedAttempt(currentAttempt);
    }
  }, [attempts]);

  useEffect(() => {
    const currentExam = exams.find((el) => el.id == match.params.exam_id);
    setSelectedExam(currentExam);
    const currentPurchase = purchase.find(
      (el) => el.exam_id == match.params.exam_id
    );
    setSelectedPurchase(currentPurchase);
    if (currentExam && currentPurchase) {
      var currentPP = currentExam.practices.filter((el) =>
        currentPurchase.practice_id.includes(el.id)
      );
      currentPP.unshift({
        id: "demo",
        name: "Demo Exam",
        link: currentExam.demo,
      });
      setPurchasedPractices(currentPP);
    }
    return () => {
      const currentExam = exams.find((el) => el.id == match.params.exam_id);
      setSelectedExam(currentExam);
    };
  }, [exams]);

  useEffect(() => {
    if (!purchasedPractices) {
      const currentExam = exams.find((el) => el.id == match.params.exam_id);
      setSelectedExam(currentExam);
      const currentPurchase = purchase.find(
        (el) => el.exam_id == match.params.exam_id
      );
      setSelectedPurchase(currentPurchase);
      if (currentExam && currentPurchase) {
        var currentPP = currentExam.practices.filter((el) =>
          currentPurchase.practice_id.includes(el.id)
        );
        currentPP.unshift({
          id: "demo",
          name: "Demo Exam",
          link: currentExam.demo,
        });
        setPurchasedPractices(currentPP);
      }
      return () => {
        const currentExam = exams.find((el) => el.id == match.params.exam_id);
        setSelectedExam(currentExam);
      };
    }
  });

  return (
    <MainLayout
      basePath="/home"
      loading={loading}
      //   data={exams}
      selected={selectedExam}
      onSelect={handleChange}
      exams={purchasedPractices ? purchasedPractices : []}
    >
      {selectedExam && (
        <AttemptExam
          data={selectedExam}
          userType={userType}
          purchase={selectedPurchase}
          attempt={selectedAttempt}
          practice={selectedExam}
        />
      )}
    </MainLayout>
  );
};

PracticeExam.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  userType: PropTypes.oneOf(["pro", "free"]).isRequired,
};

const mapStateToProps = ({
  exam: { filter, data, loading, purchase, attempts },
  auth: { data: userData },
}) => {
  const userType =
    userData && userData.subscription && userData.subscription.type === "pro"
      ? "pro"
      : "free";
  const authUserId = userData && userData.uid ? userData.uid : null;
  return {
    filter,
    exams: loading ? sampleData : data,
    purchase,
    attempts,
    loading,
    userType,
    authUserId,
  };
};

export default withRouter(connect(mapStateToProps)(PracticeExam));
