import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  avatar: {
    margin: theme.spacing(3),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(14),
    height: theme.spacing(14),
    fontSize: theme.spacing(8),
  },
  tier: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const Account = ({ data, dispatch }) => {
  const classes = useStyles();
  const userData = data || {};

  return (
    <Container maxWidth="lg" component="main">
      <Paper elevation={3} className={classes.paper}>
        <Avatar alt={userData.firstName} src={userData.photoUrl} className={classes.avatar} />
        <Card elevation={1}>
          <CardHeader
            title={userData.lastName ? `${userData.firstName} ${userData.lastName}` : userData.firstName}
            subheader={userData.email}
            titleTypographyProps={{ align: 'center' }}
            subheaderTypographyProps={{
              align: 'center',
              component: 'a',
              href: `mailto:${userData.email}`,
            }}
            action={
              userData.subscription && userData.subscription.type === 'pro' ? (
                <StarIcon color="primary" />
              ) : (
                <StarBorderIcon color="disabled" />
              )
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  autoFocus
                  id="firstName"
                  autoComplete="fname"
                  name="firstName"
                  label="First Name"
                  value={userData.firstName || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  autoComplete="lname"
                  label="Last Name"
                  value={userData.lastName || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  label="Email Address"
                  value={userData.email || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button fullWidth variant="contained" color="primary">
              Update profile
            </Button>
          </CardActions>
        </Card>
      </Paper>
    </Container>
  );
};

Account.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { data } }) => ({ data });

export default connect(mapStateToProps)(Account);
