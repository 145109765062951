import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import PagesIcon from "@material-ui/icons/Pages";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles({});

export default function Modal({
  spread,
  pagesData,
  setCurrentPage,
  markIncomplete,
  open,
  onClose,
}) {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    setCurrentPage(value);
    markIncomplete(value - 1);
    spread.setActiveSheetIndex(value);
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>
        <PagesIcon /> Navigator - select a question to go to it
      </DialogTitle>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Question #</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Flagged for Review</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagesData.map((page) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={page.id}>
                    <TableCell onClick={() => handleListItemClick(page.id)}>
                      Question #{page.id + 1}
                    </TableCell>
                    <TableCell>{page.status}</TableCell>
                    <TableCell align="center">
                      {page.flag && <CheckIcon />}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
