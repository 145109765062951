import { replaceWithPublicURL } from 'service/firebase';

export const UPDATE_FILTER = 'UPDATE_FILTER';
export const EXAM_ADDED = 'EXAM_ADDED';
export const EXAM_ADDED_ALL = 'EXAM_ADDED_ALL';
export const EXAM_REMOVED_ALL = 'EXAM_REMOVED_ALL';
export const EXAM_CHANGED = 'EXAM_CHANGED';
export const EXAM_REMOVED = 'EXAM_REMOVED';
export const EXAM_LOADING = 'EXAM_LOADING';
export const USER_PURCHASE_CHANGED = 'USER_PURCHASE_CHANGED';
export const USER_ATTEMPT_CHANGED = 'USER_ATTEMPT_CHANGED';
export const CURRENT_EXAM = 'CURRENT_EXAM';

export const examLoadingStatus = status => dispatch => {
  dispatch({
    type: EXAM_LOADING,
    payload: {
      status,
    },
  });
};

export const examAddedAll = datas => async dispatch => {
  const promises = datas.map(async snap => {
    const data = await replaceWithPublicURL(snap);
    return data;
  });

  const exams = await Promise.all(promises);

  dispatch({
    type: EXAM_ADDED_ALL,
    payload: {
      datas: exams,
    },
  });
};

export const examRemovedAll = () => dispatch => {
  dispatch({
    type: EXAM_REMOVED_ALL,
  });
};

export const examAdded = (key, data) => async (dispatch, getState) => {
  const {
    exam: { data: examList },
  } = getState();
  if (examList.find(exam => exam.id === data.id)) return;

  dispatch(examLoadingStatus(true));
  const exam = await replaceWithPublicURL(data);

  dispatch({
    type: EXAM_ADDED,
    payload: {
      key,
      data: exam,
    },
  });
  dispatch(examLoadingStatus(false));
};

export const examChanged = (key, data) => async dispatch => {
  dispatch(examLoadingStatus(true));
  const exam = await replaceWithPublicURL(data);

  dispatch({
    type: EXAM_CHANGED,
    payload: {
      key,
      data: exam,
    },
  });
  dispatch(examLoadingStatus(false));
};

export const examRemoved = key => dispatch => {
  dispatch({
    type: EXAM_REMOVED,
    payload: {
      key,
    },
  });
};

// purchase
export const purchaseAddedAll = data => dispatch => {
  dispatch({
    type: USER_PURCHASE_CHANGED,
    payload: {
      data,
    },
  });
};

export const userPurchaseChanged = data => dispatch => {
  dispatch({
    type: USER_PURCHASE_CHANGED,
    payload: {
      data,
    },
  });
};

// attempt
export const attemptAddedAll = data => dispatch => {
  dispatch({
    type: USER_ATTEMPT_CHANGED,
    payload: {
      data,
    },
  });
};

export const userAttemptChanged = data => dispatch => {
  dispatch({
    type: USER_ATTEMPT_CHANGED,
    payload: {
      data,
    },
  });
};

export const updateFilter = (key, value, clear) => ({
  type: UPDATE_FILTER,
  payload: {
    key,
    value,
    clear,
  },
});

export const updateCurrentExam = data => dispatch => {
  dispatch({
    type: CURRENT_EXAM,
    payload: {
      data,
    },
  });
};
