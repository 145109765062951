import React, { useState } from "react";
import { Container, Grid, Typography, Card, CardContent, IconButton } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const images = [
  // "https://firebasestorage.googleapis.com/v0/b/testexcel-41d92.appspot.com/o/charities%2Fimages%2F2020%2Fheroes.png?alt=media&token=9c4a7a60-a5c2-4a76-8e18-a9130dbe7e6b",
  "https://firebasestorage.googleapis.com/v0/b/testexcel-41d92.appspot.com/o/charities%2Fimages%2F2020%2FIMG_6926.jpg?alt=media&token=b71fa9e7-c65f-400c-a06a-fcad3c9cbe8c",
  "https://firebasestorage.googleapis.com/v0/b/testexcel-41d92.appspot.com/o/charities%2Fimages%2F2020%2FIMG_6931.jpg?alt=media&token=31dc51f9-4285-4328-87f9-cbfbca8ebb26",
  "https://firebasestorage.googleapis.com/v0/b/testexcel-41d92.appspot.com/o/charities%2Fimages%2F2020%2Fmmexport1587528885641.jpg?alt=media&token=7d0899e1-253a-4c2a-9ea1-791859a30b38",
  "https://firebasestorage.googleapis.com/v0/b/testexcel-41d92.appspot.com/o/charities%2Fimages%2F2020%2Fnys%20letter%20(2).jpg?alt=media&token=c9e42fc1-268e-425a-bfcd-cfc9363d6c80"
];

const Charities = () => {
  const [open, setOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const nextSlide = () => {
    if(slideIndex !== images.length){
      setSlideIndex(slideIndex + 1)
    } 
    else if (slideIndex === images.length){
      setSlideIndex(1)
    }
  }

  const prevSlide = () => {
      if(slideIndex !== 1){
        setSlideIndex(slideIndex - 1)
      }
      else if (slideIndex === 1){
        setSlideIndex(images.length)
      }
  }

  const moveDot = index => {
    setSlideIndex(index)
  }

  return (
    <div className="charities">
      <Container>
        <br />
        <Typography variant="h4" gutterBottom>
          ActuaryFellow Giveback Program
        </Typography>
        <Typography variant="subtitle1">
          The ActuaryFellow Giveback program was launched in 2020 to allocates a
          portion of our revenues for charitable giving and{" "}
          <strong>
            let our students direct donations to charities of their choice
          </strong>.
        </Typography>
        <ul>
          <li>Each student is given at least $20 of charitable giving fund.</li>

          <li>
            Soon after the end of exam period, we send students electronic charity
            giftcard.
          </li>
        </ul>
        <Typography variant="subtitle1">
          Together we can make our community better!
        </Typography>

        <hr style={{ background: '#99aab5', marginBottom: '1rem' }}/>

        <Card style={{ maxWidth: '700px', margin: 'auto' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              2020
            </Typography>

            <hr style={{ marginBottom: '1rem' }}/>
            <Grid container spacing={1}>
              <Grid item xs={12} md={7}>
                <ul>
                  <li>Mask Supplies to NY Presbyterian Hospital: $2,000</li>

                  <li>NYS Covid Fund $1,200</li>

                  <li>Macaulay Honors College $800</li>

                  <li>PPE to NYC $398.97</li>
                  <li>Harvesters $50</li>
                </ul>
              </Grid>
              <Grid item xs={12} md={5}>
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/testexcel-41d92.appspot.com/o/charities%2Fimages%2F2020%2Fheroes.png?alt=media&token=9c4a7a60-a5c2-4a76-8e18-a9130dbe7e6b"
                  alt="Stuff"
                  style={{ width: '100%' }} />

                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                  View
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card style={{ maxWidth: '700px', margin: 'auto' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              2021
            </Typography>

            <hr style={{ marginBottom: '1rem' }}/>

            <p>Coming Soon</p>
          </CardContent>
        </Card>
      </Container>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <div className="container-slider">
          {images.map((image, index) => {
            return (
              <div
                key={index}
                className={slideIndex === index + 1 ? "slide active-anim" : "slide"}
              >
                <img 
                  src={image}
                />
              </div>
            )
          })}

          <button className="btn-slide next" onClick={nextSlide}>
            <KeyboardArrowRightIcon />
          </button>
          <button className="btn-slide prev" onClick={prevSlide}>
            <KeyboardArrowLeftIcon />
          </button>

          <div className="container-dots">
              {Array.from({length: images.length}).map((item, index) => (
                <div 
                  onClick={() => moveDot(index + 1)}
                  className={slideIndex === index + 1 ? "dot active" : "dot"}
                ></div>
              ))}
          </div> 
        </div>
      </Dialog>
    </div>
  );
};

export default Charities;
