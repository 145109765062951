import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="landing">
      <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
        TERMS OF USE
        <br />
        <br />
        Thank you for visiting ActuaryFellow’s Website (“Website”). By visiting,
        browsing and using the Website, including any applications, platforms,
        services, or other resources related to, distributed on or available
        through https://www.actuaryfellow.com User agrees to be bound by these
        Terms of Use (“Terms”).
        <br />
        <br />
        USE OF THE WEBSITE
        <br />
        <br />
        This Website is provided as a service of ActuaryFellow. All features,
        content, products, and information on the Website are subject to change
        at any time without notice.
        <br />
        <br />
        The design of the Website and all trademarks, logos, trade names,
        documents, databases, graphic representations, content, and other
        material displayed on or available through the Website are the property
        of ActuaryFellow, or used by ActuaryFellow with permission, and are
        protected by copyright, trademark and other laws, whether or not a
        notice of copyright, trademark, or other proprietary rights appears on
        the screen displaying the information, and may not be used except as
        permitted in these Terms or with prior written permission of the
        ActuaryFellow (see below).
        <br />
        <br />
        Users of this Website may save and use information contained on the
        Website only for personal or other non-commercial, educational purposes;
        requests to use information, content or other material on the Website
        can be made by contacting ActuaryFellow at 99 Wall Street Suite#668, New
        York, NY 10005 or alternatively send an email to
        actuaryfellow@outlook.com.
        <br />
        <br />
        You may not in any way or reproduce or publicly display, perform,
        distribute or otherwise use any of the information or materials
        displayed on or available through the Website for any public or
        commercial purpose. You may not create any derivative work of the
        Website or decompile, reverse engineer, or otherwise attempt to derive
        source code, underlying ideas, algorithms, structure, or organization of
        the Website. You agree not to use or attempt to use any engine,
        software, tool, agent or other device or mechanism (including without
        limitation any type of spider, web crawler, or robot) to navigate,
        search, compromise, alter, render unavailable, and/or extract
        information from the Website.
        <br />
        <br />
        COPYRIGHTS
        <br />
        <br />
        All content on this site including, but not limited to, logos, graphics,
        text, button icons, images, and academic content are the property of
        ActuaryFellow, or its content suppliers, and is protected by copyright
        laws. Users of this site may save and use information contained on the
        site for personal, educational purposes only. No publications or
        materials may be downloaded (without purchase), reproduced, sold to
        third parties, or transmitted in any form or by any means. No other use,
        without limitation, may be made without the prior written permission of
        ActuaryFellow. Requests should be sent to 99 Wall Street Suite#668, New
        York, NY 10005 or alternatively send an email to
        actuaryfellow@outlook.com.
        <br />
        <br />
        DISCLAIMERS
        <br />
        <br />
        USE OF THE WEBSITE IS AT USER’S RISK. THE INFORMATION, MATERIALS AND
        SERVICES PROVIDED ON OR THROUGH THE WEBSITE ARE PROVIDED "AS IS" WITHOUT
        ANY WARRANTIES OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL
        PROPERTY. THE ACTUARY FELLOW DOES NOT WARRANT THE ACCURACY OR
        COMPLETENESS OF THE INFORMATION, MATERIALS OR SERVICES PROVIDED ON OR
        THROUGH THE WEBSITE. THE FOREGOING EXCLUSIONS OF IMPLIED WARRANTIES DO
        NOT APPLY TO THE EXTENT PROHIBITED BY LAW.
        <br />
        <br />
        Moreover, ActuaryFellow makes no warranty that its website, or the
        server that makes it available, is free of viruses, worms, or other
        elements or codes that manifest contaminating or destructive properties.
        We assume no responsibility nor liability for any damages to, or any
        viruses that may infect, your computer, telecommunication equipment, or
        other property caused by or arising from your access to, use of, or
        browsing the Website, or your downloading of any information or
        materials from the Website. ACTUARYFELLOW EXPRESSLY DISCLAIMS ANY AND
        ALL LIABILITY OR RESPONSIBILITY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL OR OTHER DAMAGES ARISING OUT OF ANY USER’S USE
        OF, REFERENCE TO, RELIANCE ON, OR INABILITY TO USE, THE WEBSITE OR THE
        INFORMATION PRESENTED ON THE WEBSITE.
      </Container>
    </div>
  );
};

export default TermsOfService;
