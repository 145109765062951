import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Box, Button } from "@material-ui/core";
import { IO } from "@grapecity/spread-excelio";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { MAX_ATTEMPTS } from "config/valiables";

// import { storage } from '../firebase';
import { firebaseDB, firebaseStorage } from "service/firebase";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import MenuBookIcon from "@material-ui/icons/MenuBook";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn: {
    marginRight: "1rem",
  },
}));

const TopNav = React.memo(
  ({
    authUserId,
    currentPage,
    pageCount,
    spread,
    examId,
    practiceId,
    type,
  }) => {
    const history = useHistory();
    const classes = useStyles();

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(4);
    const [showSave, setShowSave] = useState(false);

    function updateTime() {
      if (hours == 0 && minutes == 0 && seconds == 0) {
        alert("Test is over");
        setShowSave(true);
      } else {
        if (seconds == 0) {
          if (minutes == 0) {
            setHours((hours) => hours - 1);
            setMinutes(60);
          }
          setMinutes((minutes) => minutes - 1);
          setSeconds(59);
        } else {
          setSeconds((seconds) => seconds - 1);
        }
      }
    }

    useEffect(() => {
      // use set timeout and be confident because updateTime will cause rerender
      // rerender mean re call this effect => then it will be similar to how setinterval works
      // but with easy to understand logic
      if (!showSave) {
        const token = setTimeout(updateTime, 1000);

        return function cleanUp() {
          clearTimeout(token);
        };
      }
    });

    useEffect(() => {
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }, []);

    const alertUser = (e) => {
      e.preventDefault();
      e.returnValue = "wefjweio";
    };

    const save = () => {
      let excelIo = new IO();
      console.log(
        `A_Exams/attempts/${examId}/${type}/${authUserId}_${practiceId}.txt`
      );

      // get the data from spread.js
      var json = spread.toJSON({
        includeBindingSource: true,
      });

      const fileData = JSON.stringify(json);
      const blob = new Blob([fileData], { type: "text/plain" });

      var excelFile = blob;

      // Save the excel file to firebase storage
      const uploadTask = firebaseStorage
        .ref(
          `A_Exams/attempts/${examId}/${type}/${authUserId}_${practiceId}.txt`
        )
        .put(excelFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          // Error function
          console.log(error);
          alert("Something went wrong");
        },
        () => {
          history.push(`/home/exam/${examId}`);
        }
      );
    };

    return (
      <AppBar position="static">
        <Toolbar variant="dense" className={classes.root}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.btn}
            onClick={save}
          >
            Save and Exit
          </Button>
          <div>
            <Box display="flex">
              <AccessTimeIcon />
              <Typography variant="body1">
                Time Remaining {hours}:{minutes > 9 ? minutes : "0" + minutes}:
                {seconds > 9 ? seconds : "0" + seconds}
              </Typography>
            </Box>

            <Box display="flex">
              <MenuBookIcon />
              <Typography variant="body1">
                {currentPage + 1} of {pageCount}
              </Typography>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
);

const mapStateToProps = ({ auth: { loading, data }, exam: { attempts } }) => ({
  loading,
  authUserId: data && data.uid ? data.uid : null,
  attempts,
});

export default connect(mapStateToProps)(TopNav);
