import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingTop: 20,
    paddingBottom: 15,
    background: "#dddddd",
  },
  footnote: {
    paddingRight: 24, // keep right padding when drawer closed
    paddingTop: 15,
    paddingBottom: 15,
    background: "#CCCCCC",
  },
  footnoteTitle: {
    fontSize: "18px",
    marginBottom: "-10px",
    color: "#5A8CCD",
  },
  footnoteUnderline: {
    textDecoration: "underline",
    color: "#6FA7D6",
  },
  link: {
    marginRight: 20,
    color: "#00CEFF",
    fontSize: "16px",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <Toolbar className={classes.footnote}>
        <Container>
          <p className={classes.footnoteTitle}>
            <RouterLink className={classes.footnoteUnderline} to="/charities">
              ActuaryFellow Giveback:
            </RouterLink>{" "}
            Together we can make our community better!
          </p>
          <p>
            We allocate a portion of our revenues for charitable giving and let
            our students to direct donations to charities of their choice.
          </p>
        </Container>
      </Toolbar>
      <Toolbar className={classes.toolbar}>
        <Container>
          <Box>
            <Link
              className={classes.link}
              component={RouterLink}
              variant="button"
              color="textPrimary"
              to="/termsofservice"
            >
              Terms of Service
            </Link>
            <Link
              className={classes.link}
              component={RouterLink}
              variant="button"
              color="textPrimary"
              to="/privacypolicy"
            >
              Privacy Policy
            </Link>
            <Link
              className={classes.link}
              component={RouterLink}
              variant="button"
              color="textPrimary"
              to="/contactus"
            >
              Contact Us
            </Link>
            <Link
              className={classes.link}
              component={RouterLink}
              variant="button"
              color="textPrimary"
              to="/aboutus"
            >
              About Us
            </Link>
          </Box>
          <p>Copyright - 2021</p>
        </Container>
      </Toolbar>
    </footer>
  );
};

export default Footer;
