import React from 'react';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import SubscriberEmail from './SubscriberEmail';

function LandingInfor({ currentExam }) {
  let content;
  switch(currentExam && currentExam.id){
    case 'exam5':
      content = (
        <Container style={{ marginTop: '10px'}}>
          <SubscriberEmail
            link="https://app.convertkit.com/forms/2175019/subscriptions"
            examNumber="5" />
        </Container>
      );
      break;
    case 'exam6':
      content = (
        <Container style={{ marginTop: '10px'}}>
          <SubscriberEmail
            link="https://app.convertkit.com/forms/2175024/subscriptions"
            examNumber="6" />
        </Container>
      );
      break;
    case 'exam7':
      content = (
        <Container style={{ marginTop: '10px'}}>
          <SubscriberEmail
            link="https://app.convertkit.com/forms/2175017/subscriptions"
            examNumber="7" />
        </Container>
      );
      break;
    case 'exam8':
      content = (
        <Container style={{ marginTop: '10px'}}>
          <SubscriberEmail
            link="https://app.convertkit.com/forms/2175015/subscriptions"
            examNumber="8" />
        </Container>
      );
      break;
    case 'exam9':
      content = (
        <Container style={{ marginTop: '10px'}}>
          <Paper style={{ padding: '0 10px 20px 10px', marginBottom: '30px'}} elevation={0}>
            <div className="elfsight-app-f11b141a-c0d7-4534-a603-914f2b8946dc"></div>
          </Paper>

          <SubscriberEmail
            link="https://app.convertkit.com/forms/2174034/subscriptions"
            examNumber="9" />

          <Card>
            <CardContent>
              <Typography variant="subtitle1" align="center" paragraph>
                Visit our <a href="https://forum.actuaryfellow.org/" target="_blank" rel="noopener noreferrer">student forum</a> to get FREE resources(video solution and more) and help on your questions
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <img style={{ width: '150px', marginLeft: '300px'}} src="https://firebasestorage.googleapis.com/v0/b/actuaryfellow.appspot.com/o/images%2FAF%20fox.jpg?alt=media&token=a6fbeeb2-4a8f-4fd6-a995-1c6e9b0707ec" alt="Logo" />
                </Grid>

                <Grid item xs={12} md={9}>
                  <Typography variant="subtitle1" align="center" style={{ marginTop: '30px'}}>
                    <a href="https://www.actuaryfellow.com/courses/course-v1:actuaryfellow+Exam9_Online_Seminar+2021_spring/about" target="_blank" rel="noopener noreferrer">Exam 9 Joint Online Seminar (All Inclusive)</a>
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    <a href="https://www.actuaryfellow.com/courses/course-v1:actuaryfellow+Exam9_Manual+2021_spring/about" target="_blank" rel="noopener noreferrer">Exam 9 Joint Study Manual (All Inclusive)</a>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      );
      break;
    default:
      content = <p>Not Found</p>
  }
  return content;
}

export default LandingInfor;
