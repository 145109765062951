import { firebaseDB } from 'service/firebase';

export const AUTH_CHANGED = 'AUTH_CHANGED';
export const AUTH_LOADING = 'AUTH_LOADING';
export const USER_BOOKMARK_CHANGED = 'USER_BOOKMARK_CHANGED';
export const USER_SUBSCRIPTION_CHANGED = 'USER_SUBSCRIPTION_CHANGED';

export const authLoadingStatus = status => dispatch => {
  dispatch({
    type: AUTH_LOADING,
    payload: {
      status,
    },
  });
};

export const authChanged = user => async dispatch => {
  let userData = null;
  try {
    if (user && user.uid) {
      const [userSnapShot, subscriptionSnapShot] = await Promise.all([
        firebaseDB.ref(`users/${user.uid}`).once('value'),
        firebaseDB.ref(`subscriptions/${user.uid}`).once('value'),
      ]);
      userData = { ...(userSnapShot.val() || {}), subscription: subscriptionSnapShot.val() };
    }
  } catch (err) {
    console.error(err);
  }
  dispatch({
    type: AUTH_CHANGED,
    payload: {
      data: userData,
    },
  });
};

export const userBookMarkChanged = data => dispatch => {
  dispatch({
    type: USER_BOOKMARK_CHANGED,
    payload: {
      data,
    },
  });
};

export const userSubscriptionChanged = data => dispatch => {
  dispatch({
    type: USER_SUBSCRIPTION_CHANGED,
    payload: {
      data,
    },
  });
};
