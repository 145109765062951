import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/auth';

import firebaseConfig from './FBconfig.json';

// const db = firebaseApp.firestore();
firebase.initializeApp(firebaseConfig);

export const provider = new firebase.auth.GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');
provider.setCustomParameters({
  prompt: 'select_account',
});
// provider.addScope('https://www.googleapis.com/auth/plus.me');

export const firebaseAuth = firebase.auth();
export const firebaseDB = firebase.database();
export const firebaseStorage = firebase.storage();

export const replaceWithPublicURL = async data => {
  if (!data && typeof data !== 'object') return data;
  const promises = Object.keys(data)
    .filter(objKey => typeof data[objKey] === 'string' && data[objKey].startsWith('gs://'))
    .map(
      objKey =>
        new Promise(resolve => {
          const storageRef = firebaseStorage.refFromURL(data[objKey]);
          storageRef
            .getDownloadURL()
            .then(url => {
              resolve({ key: objKey, url });
            })
            .catch(err => {
              console.error(err);
              resolve({ key: objKey, url: null });
            });
        }),
    );

  const urls = await Promise.all(promises);

  return urls.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.key]: cur.url,
    };
  }, data);
};

export default firebase;
