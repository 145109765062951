import React from "react";
import Container from "@material-ui/core/Container";

const AboutUs = () => {
  return (
    <div className="landing">
      <Container style={{ marginTop: "50px", marginBottom: "50px" }}>
        <div>
          <h2>
            {" "}
            <font color="00CEFF">Our Mission</font>
          </h2>
          <br />
          Our mission is to share an effective study strategy with you that will
          help you pass any upper-level exam on your FIRST attempt.
          <br />
          <br />
          Our learning platform is designed to help you learn and master
          concepts from source materials effectively and quickly.
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
