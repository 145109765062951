import {
  UPDATE_FILTER,
  EXAM_ADDED,
  EXAM_ADDED_ALL,
  EXAM_REMOVED_ALL,
  EXAM_CHANGED,
  EXAM_REMOVED,
  EXAM_LOADING,
  USER_PURCHASE_CHANGED,
  USER_ATTEMPT_CHANGED,
  CURRENT_EXAM,
} from 'redux/actions/ExamActions';

const initialState = {
  data: [],
  purchase: [],
  filter: {},
  loading: false,
};

const NotificationReducer = (state = initialState, action) => {
  const exams = [...state.data];

  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        filter: {
          ...(action.payload.clear ? initialState.filter : state.filter),
          ...(action.payload.key ? { [action.payload.key]: action.payload.value } : {}),
        },
      };

    case EXAM_LOADING:
      return {
        ...state,
        loading: action.payload.status,
      };

    case EXAM_ADDED_ALL:
      return {
        ...state,
        data: action.payload.datas,
        filter: {},
      };

    case EXAM_REMOVED_ALL:
      return {
        ...state,
        data: [],
        filter: {},
      };

    case EXAM_ADDED:
      exams.splice(action.payload.key, 0, action.payload.data);
      return {
        ...state,
        data: exams,
      };

    case EXAM_CHANGED:
      exams.splice(action.payload.key, 1, action.payload.data);
      return {
        ...state,
        data: exams,
      };

    case EXAM_REMOVED:
      exams.splice(action.payload.key, 1);
      return {
        ...state,
        data: exams,
      };

    case USER_PURCHASE_CHANGED:
      if (!state.data) break;
      return {
        ...state,
        purchase: action.payload.data,
      };

    case USER_ATTEMPT_CHANGED:
      if (!state.data) break;
      return {
        ...state,
        attempts: action.payload.data,
      };
    case CURRENT_EXAM:
      if (!state.data) break;
      return {
        ...state,
        current_exam: action.payload.data,
      };

    default:
      return state;
  }
};

export default NotificationReducer;
