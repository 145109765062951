import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  image: {
    position: 'relative',
    objectFit: 'fill',
    width: '100%',
    minHeight: 160,
  },
});

const Image = React.memo(({ src, ...props }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);

  if (!src || error) {
    return <img alt="Blank" {...props} className={classes.image} src="./alternative.jpg" />;
  }

  return (
    <img
      alt="Card"
      {...props}
      className={classes.image}
      src={src}
      onLoad={() => {
        setError(false);
      }}
      onError={() => {
        setError(true);
      }}
    />
  );
});

Image.defaultProps = {
  src: null,
};

Image.propTypes = {
  src: PropTypes.string,
};

export default Image;
