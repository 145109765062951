import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { DRAWER_WIDTH } from 'config/styles';
import { SECTIONS } from 'config/valiables';
import sampleExams from 'config/exam.json';

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
}));

export const MainSideBar = ({ open, selected, onSelect, onClose, exams }) => {
  const classes = useStyles();

  const DrawerContent = (
    <React.Fragment>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListSubheader inset>Exams</ListSubheader>
        <Divider />
        {exams.map(exam => (
          <React.Fragment key={exam.id}>
            <ListItem button selected={selected === exam.id} onClick={event => onSelect(event, exam)}>
              <ListItemText primary={exam.name} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={onClose}
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {DrawerContent}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          {DrawerContent}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

MainSideBar.defaultProps = {
  open: true,
  selected: null,
};

MainSideBar.propTypes = {
  open: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const AttemptSideBar = ({ open, selected, onSelect, onClose, exams }) => {
  const classes = useStyles();

  const DrawerContent = (
    <React.Fragment>
      <Divider />
      <List>
        {/* <ListSubheader inset>Exams</ListSubheader> */}
        {/* <Divider /> */}
        {exams.map(exam => (
          <React.Fragment key={exam.id}>
            <ListItem button selected={selected === exam} onClick={event => onSelect(event, exam)}>
              <ListItemText primary={exam.name} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </React.Fragment>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <React.Fragment>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
          style={{ marginLeft: '36px', border: 'ridge 1px' }}
        >
          {DrawerContent}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

AttemptSideBar.defaultProps = {
  open: true,
  selected: null,
};

AttemptSideBar.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

const SideBar = ({ open, selected, onSelect, onClose }) => {
  const classes = useStyles();

  const DrawerContent = (
    <React.Fragment>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListSubheader inset>Sections</ListSubheader>
        {SECTIONS.map(section => (
          <ListItem
            key={section.value}
            button
            selected={selected === section.value}
            onClick={event => onSelect(event, section.value)}
          >
            <ListItemText primary={section.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </React.Fragment>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <React.Fragment>
      <Hidden mdUp>
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={onClose}
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {DrawerContent}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          {DrawerContent}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

SideBar.defaultProps = {
  open: true,
  selected: null,
};

SideBar.propTypes = {
  open: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SideBar;
