import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import { PRICE_DESCRIPTION_FONT_SIZE } from 'config/styles';

import { PayPalButton } from 'react-paypal-button-v2';
import paypalConfig from 'service/paypal.json';

import { firebaseDB, firebaseStorage } from 'service/firebase';
import { getToday } from 'utils/getToday';

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],

    '& .MuiCardHeader-title': {
      fontSize: '34px !important',
    },
  },
  cardPricing: {
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  list: ({ height }) => ({
    height,
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '1px solid',
  }),
  link: {
    margin: 'auto',
    width: '70%',
    marginTop: '40px',
  },
}));

const LINE_HEIGHT = 1.75;

const Subscribe = withRouter(({ location, purchase, current_exam, authUserId, match }) => {
  const history = useHistory();
  let tiers = [];
  let tier = {};
  let bundle = {};
  const c_exam_purchase = purchase.find(item => item.exam_id == current_exam.id);
  const purchase_index = purchase.findIndex(item => item == c_exam_purchase);
  if (current_exam?.bundles) {
    const bundles = current_exam.bundles;
    bundle = bundles.find(item => item.id == match.params.bundle_id);
    tier['title'] = `${current_exam.name} Bundle Purchase`;
    tier['buttonText'] = bundle.price == 0 ? 'Start for free' : 'Purchase';
    tier['buttonVariant'] = bundle.price == 0 ? 'outlined' : 'contained';
    tier['path'] =
      bundle.price == 0
        ? `/home/exam/${current_exam.id}`
        : `/home/subscribe/${current_exam.id}/${bundle.id}`;
    tier['description'] = bundle.practices.map(practice_id => {
      if (practice_id == 'flashcard') {
        return 'Flashcards';
      }
      const practice = current_exam.practices.find(practice => practice.id == practice_id);
      return practice.name;
    });
    tier['price'] = bundle.price.toString();
    tiers.push(tier);
  }
  const height = tiers.reduce((acc, cur) => {
    const itemHeight = cur.description.length * PRICE_DESCRIPTION_FONT_SIZE * LINE_HEIGHT + 20;
    return itemHeight > acc ? itemHeight : acc;
  }, 0);

  const classes = useStyles({ height });
  const state = { from: location.pathname };

  const subscribeBundle = () => {
    const today = getToday();
    let purchased_list = c_exam_purchase.practice_id;
    let purchased_date_list = c_exam_purchase.purchase_date;
    bundle.practices.forEach(item => {
      if (!c_exam_purchase.practice_id.includes(item)) {
        purchased_list.push(item);
        purchased_date_list.push({ id: item, date: today });
      } else {
        const date_index = purchased_date_list.findIndex(el => el.id == item);
        purchased_date_list[date_index].date =
          purchased_date_list[date_index].date == 'free' ? 'free' : today;
      }
    });
    var purchaseRef = firebaseDB.ref(`purchase/${authUserId}/${purchase_index}`);
    purchaseRef.update({
      practice_id: purchased_list,
      purchase_date: purchased_date_list,
    });
    history.push('/home/pricing');
  };
  return (
    <React.Fragment>
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}></Container>
      {/* End hero unit */}
      <Container maxWidth="sm" component="main">
        <Grid container spacing={8} alignItems="flex-end">
          {tiers.map(tier => {
            return (
              <Grid item key={tier.title} xs={12} sm={12}>
                <Card style={{ width: '70%', margin: 'auto' }}>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={<StarIcon color="primary" />}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    {tier.price !== undefined && tier.price !== null ? (
                      <div className={classes.cardPricing}>
                        <Typography component="h2" variant="h3" color="textPrimary">
                          ${tier.price}
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.cardPricing} style={{ alignItems: 'center' }}>
                        <Typography component="h4" variant="h5" color="textSecondary">
                          Pending
                        </Typography>
                      </div>
                    )}
                    <ul className={classes.list}>
                      {tier.description.map(line => (
                        <Typography
                          key={line}
                          component="li"
                          variant="subtitle1"
                          align="center"
                          noWrap
                          style={{ fontSize: '19px', lineHeight: LINE_HEIGHT }}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <div className={classes.link}>
                      <PayPalButton
                        amount={tier.price}
                        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                        onSuccess={(details, data) => {
                          subscribeBundle();
                          alert('Transaction completed by ' + details.payer.name.given_name);
                          // OPTIONAL: Call your server to save the transaction
                          // return fetch("/paypal-transaction-complete", {
                          //   method: "post",
                          //   body: JSON.stringify({
                          //     orderId: data.orderID
                          //   })
                          // });
                        }}
                        options={{
                          clientId: paypalConfig.clientId,
                        }}
                      />
                      {/* <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color="primary"
                      onClick={subscribeBundle}
                      className={classes.link}
                    >
                      {tier.buttonText}
                    </Button> */}
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </React.Fragment>
  );
});

Subscribe.propTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  exam: { filter, data, loading, purchase, current_exam },
  auth: { data: userData },
}) => {
  const userType =
    userData && userData.subscription && userData.subscription.type === 'pro' ? 'pro' : 'free';
  const authUserId = userData && userData.uid ? userData.uid : null;
  return { filter, purchase, loading, userType, current_exam, authUserId };
};

export default withRouter(connect(mapStateToProps)(Subscribe));
