import React, { useEffect, useState } from 'react';
import { useRouteMatch, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firebaseDB } from 'service/firebase';
import Typography from '@material-ui/core/Typography';
import PurchaseExam from 'components/PurchaseExam';
import PriceCards from 'components/PriceCards';
import { MainLayout } from 'components/Layout';
import { purchaseFiltered } from 'utils/filter';

import { updateCurrentExam } from 'redux/actions/ExamActions';

import sampleData from 'config/sample.json';

const Pricing = ({ loading, exams, current_exam, purchase, userType, dispatch }) => {
  const { path } = useRouteMatch();
  const [selectedExam, setSelectedExam] = useState();
  const [selectedPurchase, setSelectedPurchase] = useState();

  useEffect(() => {
    if (current_exam) {
      setSelectedExam(current_exam);
    } else {
      setSelectedExam(exams[0]);
    }
  }, [exams, current_exam]);

  const handleChange = exam => {
    dispatch(updateCurrentExam(exam));
    setSelectedExam(exam);
    const currentPurchase = purchase.find(el => el.exam_id == exam.id);
    setSelectedPurchase(currentPurchase);
  };

  return (
    <MainLayout
      basePath="/home"
      loading={loading}
      selected={selectedExam}
      onSelect={handleChange}
      exams={exams}
    >
      {selectedExam && (
        <div style={{ marginTop: '50px' }}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            {selectedExam.name} Pricing
          </Typography>
          <PriceCards currentExam={selectedExam} isAuthorized={true} />
        </div>
      )}
    </MainLayout>
  );
};

Pricing.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  userType: PropTypes.oneOf(['pro', 'free']).isRequired,
};

const mapStateToProps = ({
  exam: { filter, data, loading, purchase, current_exam },
  auth: { data: userData },
}) => {
  // const userType =
  //   userData && userData.subscription && userData.subscription.type === 'pro' ? 'pro' : 'free';
  const current_purchase = current_exam ? purchase.find(el => el.exam_id == current_exam.id) : {};
  const userType =
    current_exam &&
    current_purchase?.practice_id &&
    purchaseFiltered(current_purchase.purchase_date).includes('flashcard')
      ? 'pro'
      : 'free';
  return { filter, exams: loading ? sampleData : data, purchase, loading, userType, current_exam };
};

export default withRouter(connect(mapStateToProps)(Pricing));
