import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Lightbox from "react-image-lightbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { AttemptSideBar } from "components/Layout/SideBar";
import { makeStyles } from "@material-ui/core/styles";
import sampleData from "config/exam.json";
import sampleFile from "config/exam_8_demo.xlsx";
import { Divider } from "@material-ui/core";
import { MAX_ATTEMPTS } from "config/valiables";

import { firebaseDB, firebaseStorage } from "service/firebase";

import { attemptAddedAll, userAttemptChanged } from "redux/actions/ExamActions";

const useStyles = makeStyles({
  card: {
    height: "100%",
    padding: 0,
    cursor: "pointer",
    border: "1px solid",
  },
  cardBtn: {
    width: "100%",
    height: "80px",
  },
  cardDescription: {
    textAlign: "center",
    height: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardDemo: {
    margin: "auto",
    marginTop: "20px",
    width: "80%",
    padding: "20px",
    paddingTop: "50px",
  },
  cardDemoBtn: {
    marginLeft: "10px",
    width: "40%",
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
});

const AttemptExam = React.memo(
  ({
    data,
    userType,
    purchase,
    attempt,
    practice,
    attempts,
    authUserId,
    dispatch,
  }) => {
    const classes = useStyles();
    const history = useHistory();
    const [practiceAttempts, setPracticeAttempts] = useState([]);
    const [selectedAttempt, setSelectedAttempt] = useState();

    const handleChange = (event) => {
      setSelectedAttempt(event);
    };
    const handleStart = (event) => {
      var total_attempt = 1;
      if (attempt && attempt.total_attempt < MAX_ATTEMPTS) {
        total_attempt = attempt.total_attempt + 1;
        const attempt_number = attempts.indexOf(attempt);
        const attemptRef = firebaseDB.ref(
          `attempts/${authUserId}/${attempt_number}`
        );
        attemptRef.transaction((attempt) => {
          if (attempt) {
            attempt.total_attempt += 1;
          }
          return attempt;
        });
        const attemptsRef = firebaseDB.ref(`attempts/${authUserId}`);
        attemptsRef.once("value", (snapshot) => {
          dispatch(attemptAddedAll(snapshot.val()));
        });
        const win = window.open(
          `/home/sheet/${purchase.exam_id}/${practice.id}/${total_attempt}`,
          "_blank"
        );
        win.focus();
        // history.push(`/home/sheet/${purchase.exam_id}/${practice.id}/${total_attempt}`, {"target": "_blank"});
      }
      if (!attempt) {
        var attemptRef = firebaseDB.ref(
          `attempts/${authUserId}/${attempts.length}`
        );
        attemptRef.set({
          exam_id: purchase.exam_id,
          practice_id: practice.id,
          total_attempt: 1,
        });
        const attemptsRef = firebaseDB.ref(`attempts/${authUserId}`);
        attemptsRef.once("value", (snapshot) => {
          dispatch(attemptAddedAll(snapshot.val()));
        });
        history.push(
          `/home/sheet/${purchase.exam_id}/${practice.id}/${total_attempt}`
        );
      }
    };

    const uploadAttempt = (total_attempt) => {
      var storageRef = firebaseStorage.ref(
        `A_Exams/attempts/${purchase.exam_id}/${total_attempt}/${authUserId}_${practice.id}.xlsx`
      );
      console.log(
        `A_Exams/attempts/${purchase.exam_id}/${total_attempt}/${authUserId}_${practice.id}.xlsx`
      );
      var blob = null;
      var file = null;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", sampleFile);
      xhr.responseType = "blob"; //force the HTTP response, response-type header to be blob
      xhr.onload = function () {
        blob = xhr.response; //xhr.response is now a blob object
        file = new File([blob], "1.xlsx", {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          lastModified: Date.now(),
        });
        storageRef.put(file).then((snapshot) => {
          console.log("Uploaded successfully!");
        });
      };
      xhr.send();
    };

    useEffect(() => {
      if (attempt) {
        var currentAttempts = [];
        for (var i = 1; i <= attempt.total_attempt; i++) {
          currentAttempts.push({ name: i.toString() + " Attempt", id: i });
        }
        setPracticeAttempts(currentAttempts);
      }
      return () => {
        setPracticeAttempts([]);
        setSelectedAttempt(null);
      };
    }, [attempt, data]);

    console.log(practiceAttempts, "practiceAttempts");
    return (
      <Box>
        <Card className={classes.cardDemo}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
              <CardContent>
                <Button
                  className={classes.cardDemoBtn}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  style={{ fontSize: "20px", width: `240px` }}
                >
                  {data.name}
                </Button>
              </CardContent>
            </Grid>
            <Divider />
            {!data?.description && (
              <>
                <Grid item xs={12} sm={5}>
                  <CardContent style={{ marginLeft: "30px" }}>
                    <Button
                      className={classes.cardDemoBtn}
                      fullWidth
                      variant="contained"
                      color="primary"
                      target="_blank"
                      onClick={handleStart}
                      disabled={practiceAttempts.length >= 2}
                    >
                      Start
                    </Button>
                    <Button
                      component={RouterLink}
                      className={classes.cardDemoBtn}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      target="_blank"
                      to={`/home/sheet/${purchase.exam_id}/${practice.id}/solution`}
                    >
                      Solution
                    </Button>
                  </CardContent>
                  <Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    color="textSecondary"
                    style={{ marginLeft: "55px", marginTop: "-10px" }}
                  >
                    * 2 attempts Allowed
                  </Typography>
                </Grid>
                <AttemptSideBar
                  selected={selectedAttempt}
                  onSelect={(e, exam) => {
                    handleChange(exam);
                  }}
                  exams={practiceAttempts ? practiceAttempts : []}
                />
                <main className={classes.content}>
                  {selectedAttempt && (
                    <Box>
                      <Card className={classes.cardDemo}>
                        <CardHeader title={selectedAttempt.name} />
                        <CardContent style={{ marginLeft: "30px" }}>
                          {/* <Button
                            component={RouterLink}
                            className={classes.cardDemoBtn}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            to={`/home/sheet/${purchase.exam_id}/${practice.id}/${
                              selectedAttempt.id + MAX_ATTEMPTS
                            }`}
                            target="_blank"
                          >
                            Show Exam
                          </Button> */}
                          <Button
                            component={RouterLink}
                            className={classes.cardDemoBtn}
                            fullWidth
                            variant="contained"
                            color="primary"
                            to={`/home/sheet/${purchase.exam_id}/${practice.id}/${selectedAttempt.id}`}
                            target="_blank"
                          >
                            Resume
                          </Button>
                        </CardContent>
                      </Card>
                    </Box>
                  )}
                </main>
              </>
            )}
            {data?.description && (
              <Grid item xs={12} sm={12}>
                <Card className={classes.cardDescription}>
                  {data.description}
                </Card>
              </Grid>
            )}
          </Grid>
        </Card>
      </Box>
    );
  }
);

AttemptExam.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth: { loading, data }, exam: { attempts } }) => ({
  loading,
  authUserId: data && data.uid ? data.uid : null,
  attempts,
});

export default connect(mapStateToProps)(AttemptExam);
