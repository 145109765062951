import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, ButtonGroup, Button } from "@material-ui/core";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PagesIcon from "@material-ui/icons/Pages";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import Modal from "./Modal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  tableBtnArea: {
    flexGrow: 1,
  },
}));

export default function BottomNav({
  currentPage,
  setCurrentPage,
  spread,
  pageCount,
  markIncomplete,
  pagesData,
}) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const previousPage = () => {
    if (currentPage > 0) {
      markIncomplete(currentPage - 1);

      spread.setActiveSheetIndex(currentPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };

  const openTable = async () => {
    window.open(
      "https://firebasestorage.googleapis.com/v0/b/exceltest-96c8c.appspot.com/o/images%2Fdisttable%20image.jpg?alt=media&token=f4d880b2-f292-4b9a-b322-3ac6b0dfb5ee",
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=700"
    );
  };

  const nextPage = () => {
    if (currentPage < pageCount - 1) {
      markIncomplete(currentPage + 1);

      spread.setActiveSheetIndex(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <div className={classes.tableBtnArea}>
            <Button
              color="inherit"
              startIcon={<HelpOutlineIcon />}
              onClick={() => openTable()}
            >
              Tables
            </Button>
          </div>

          <ButtonGroup
            variant="text"
            color="inherit"
            aria-label="text primary button group"
          >
            <Button startIcon={<ArrowBackIcon />} onClick={previousPage}>
              Previous
            </Button>
            <Button startIcon={<PagesIcon />} onClick={handleClickOpen}>
              Navigator
            </Button>
            <Button endIcon={<ArrowForwardIcon />} onClick={nextPage}>
              Next
            </Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>

      <Modal
        spread={spread}
        pagesData={pagesData}
        setCurrentPage={setCurrentPage}
        markIncomplete={markIncomplete}
        open={openModal}
        onClose={handleClose}
      />
    </div>
  );
}
