import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { firebaseAuth, firebaseDB } from 'service/firebase';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(10),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  primaryLoading: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
}));

const SignUp = ({ history, location }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const onClickClose = () => {
    if (history.length > 0) history.goBack();
    else history.replace('/');
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!firstName) {
      setError({ code: 'auth/invalid-argument', message: 'The first name is required' });
      return;
    }
    setSubmitting(true);
    try {
      await firebaseAuth.createUserWithEmailAndPassword(email, password);
      const user = firebaseAuth.currentUser;
      let displayName = firstName;
      const photoURL = null;
      if (lastName) displayName += ` ${lastName}`;
      const userData = {
        firstName,
        lastName,
        photoURL,
        email: user.email,
        emailVerified: user.emailVerified,
        isAnonymous: user.isAnonymous,
        uid: user.uid,
        providerData: user.providerData,
      };
      try {
        await Promise.all([
          firebaseAuth.currentUser.updateProfile({ displayName, photoURL }),
          firebaseDB.ref(`users/${user.uid}`).set(userData),
        ]);
      } catch (err) {
        user.delete().catch(err1 => {
          console.error(err1);
        });
        throw err;
      }
      await sendEmailVerification();
      setTimeout(() => {
        setSubmitting(false);
        history.push('/signin');
      }, 2000);
    } catch (err) {
      console.error(err);
      if (err.code) setError(err);
      else setError({ code: 'auth/unexpected-error', message: error.message });
      setSubmitting(false);
    }
  };

  const sendEmailVerification = async () => {
    const user = firebaseAuth.currentUser;
    await user.sendEmailVerification();
    setError({ code: 'auth/email-verification-sent', message: 'verification email sent' });
  };

  const e = !!error && !!error.code && error.code.split('/');
  const errorHeader = e && e.length > 0 ? e[e.length - 1] : 'unexpected-error';
  const errorMessage = !!error && error.message;

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert
          severity={errorHeader === 'email-verification-sent' ? 'success' : 'error'}
          variant="standard"
        >
          <AlertTitle>{errorHeader}</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
        <IconButton className={classes.close} onClick={onClickClose}>
          <CloseIcon />
        </IconButton>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                autoFocus
                id="firstName"
                autoComplete="fname"
                name="firstName"
                label="First Name"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="lname"
                label="Last Name"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                label="Email Address"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                type="password"
                id="password"
                name="password"
                autoComplete="current-password"
                label="Password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            disabled={submitting}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
            {submitting && (
              <CircularProgress className={classes.primaryLoading} size={20} style={{ marginLeft: 5 }} />
            )}
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link
                component={RouterLink}
                to={{ pathname: '/signin', state: location.state }}
                variant="body2"
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

SignUp.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SignUp);
