import React, { useEffect } from 'react';
import { useRouteMatch, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ExamCards from 'components/ExamCards';

import sampleData from 'config/exam.json';

const HomePage = ({ loading, exams, dispatch, location }) => {
  return <ExamCards data={exams} />;
};

HomePage.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  exams: PropTypes.array.isRequired,
};

const mapStateToProps = ({ exam: { filter, data, loading } }) => {
  return { filter, exams: loading ? sampleData : data, loading };
};

export default withRouter(connect(mapStateToProps)(HomePage));
