import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#1976d2', light: '#4791db', dark: '#115293' },
    secondary: { main: '#dc004e', light: '#e33371', dark: '#9a0036' },
    success: { main: '#4caf50', light: '#81c784', dark: '#388e3c' },
    error: { main: '#f44336', light: '#e57373', dark: '#d32f2f' },
    warning: { main: '#ff9800', light: '#ffb74d', dark: '#f57c00' },
    info: { main: '#2196f3', light: '#64b5f6', dark: '#1976d2' },
  },
});

export default theme;
