import React, { useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { purchaseFiltered } from 'utils/filter';

const useStyles = makeStyles({
  card: {
    height: '100%',
    padding: 0,
    cursor: 'pointer',
    border: '1px solid',
  },
  cardBtn: {
    width: '100%',
    height: '80px',
  },
  cardDescription: {
    textAlign: 'center',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardDemo: {
    margin: 'auto',
    marginTop: '20px',
    width: '80%',
    padding: '20px',
    paddingTop: '50px',
  },
  cardDemoBtn: {
    marginLeft: '30px',
  },
});

const PurchaseExam = React.memo(({ data, userType, purchase }) => {
  const classes = useStyles();
  return (
    <Box>
      <Card className={classes.cardDemo}>
        <CardHeader title={data.name} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CardContent style={{ marginLeft: '30px' }}>
              <Button className={classes.cardDemoBtn} fullWidth color="primary" variant="outlined">
                FlashCard
              </Button>
            </CardContent>
            {data &&
              Object.keys(data).includes('practices') &&
              data.practices.map(practice => {
                return (
                  <CardContent style={{ marginLeft: '30px' }}>
                    <Button className={classes.cardDemoBtn} fullWidth color="primary" variant="outlined">
                      {practice.name}
                    </Button>
                  </CardContent>
                );
              })}
          </Grid>
          <Grid item xs={12} sm={4}>
            <CardContent style={{ marginLeft: '30px' }}>
              {userType === 'pro' ? (
                <Button
                  component={RouterLink}
                  className={classes.cardDemoBtn}
                  fullWidth
                  variant="contained"
                  color="primary"
                  to={`/home/cards/${data.id}`}
                  target="_blank"
                >
                  Start
                </Button>
              ) : (
                <Button
                  component={RouterLink}
                  className={classes.cardDemoBtn}
                  fullWidth
                  variant="contained"
                  color="primary"
                  to="/home/pricing"
                >
                  Purchase
                </Button>
              )}
            </CardContent>
            {data &&
              Object.keys(data).includes('practices') &&
              data.practices.map(practice => {
                if (purchase && purchaseFiltered(purchase.purchase_date).includes(practice.id)) {
                  return (
                    <CardContent style={{ marginLeft: '30px' }}>
                      <Button
                        component={RouterLink}
                        className={classes.cardDemoBtn}
                        fullWidth
                        variant="contained"
                        color="primary"
                        to={`/home/exam/${data.id}`}
                        target="_blank"
                      >
                        Start
                      </Button>
                    </CardContent>
                  );
                } else {
                  return (
                    <CardContent style={{ marginLeft: '30px' }}>
                      <Button
                        component={RouterLink}
                        className={classes.cardDemoBtn}
                        fullWidth
                        variant="contained"
                        color="primary"
                        to="/home/pricing"
                      >
                        Purchase
                      </Button>
                    </CardContent>
                  );
                }
              })}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
});

PurchaseExam.propTypes = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = ({ exam: { current_exam } }) => ({
  current_exam,
});

export default connect(mapStateToProps)(PurchaseExam);
