import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { firebaseAuth, firebaseDB, provider } from 'service/firebase';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(10),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  close: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  primaryLoading: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  secondaryLoading: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
}));

const SignIn = ({ history, location }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const onClickClose = () => {
    if (history.length > 0) history.goBack();
    else history.replace('/');
  };

  useEffect(() => {
    const user = firebaseAuth.currentUser;
    if (user && user.emailVerified) {
      history.replace('/home');
    }
  }, [history]);

  const handleSubmit = event => {
    event.preventDefault();

    setSubmitting(true);
    firebaseAuth
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        if (result.user.emailVerified) {
          const { from } = location.state || { from: '/home' };
          setSubmitting(false);
          history.push(from);
        } else {
          setError({ code: 'auth/email-verification-needed', message: 'verify your email before sign in' });
          setSubmitting(false);
        }
      })
      .catch(err => {
        // Handle Errors here.
        setSubmitting(false);
        setError(err);
      });
  };

  const sendEmailVerification = async () => {
    const user = firebaseAuth.currentUser;
    try {
      await user.sendEmailVerification();
      setError({ code: 'auth/email-verification-sent', message: 'verification email sent' });
    } catch (err) {
      setError(err);
    }
  };

  const handleGoogleSignin = async event => {
    setSubmitting(true);
    try {
      const result = await firebaseAuth.signInWithPopup(provider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = result.credential.accessToken;

      const [firstName, lastName] = result.user.displayName.split(' ');
      const userData = {
        firstName,
        lastName,
        photoURL: result.user.photoURL,
        email: result.user.email,
        emailVerified: result.user.emailVerified,
        isAnonymous: result.user.isAnonymous,
        uid: result.user.uid,
        providerData: [{ ...result.user.providerData[0], token }],
      };
      const snapshot = await firebaseDB.ref(`users/${result.user.uid}`).once('value');
      const prevData = snapshot.val() || {};
      if (prevData.firstName || prevData.lastName) {
        delete userData.firstName;
        delete userData.lastName;
      }
      if (prevData.photoURL) {
        delete userData.photoURL;
      }
      if (prevData.email) {
        delete userData.email;
      }
      if (prevData.emailVerified) {
        delete userData.emailVerified;
      }
      if (prevData.isAnonymous) {
        delete userData.isAnonymous;
      }
      if (prevData.uid) {
        delete userData.uid;
      }
      if (prevData.providerData) {
        const index = prevData.providerData.findIndex(
          prov => prov.providerId === userData.providerData[0].providerId,
        );
        prevData.providerData.splice(index, index >= 0 ? 1 : 0, userData.providerData[0]);
        userData.providerData = prevData.providerData;
      }

      await firebaseDB.ref(`users/${result.user.uid}`).update(userData);
      setSubmitting(false);

      const { from } = location.state || { from: '/home' };
      history.push(from);
    } catch (err) {
      console.error(err);
      // Handle Errors here.
      setError(err);
      setSubmitting(false);
    }
  };

  const e = !!error && !!error.code && error.code.split('/');
  const errorHeader = e && e.length > 0 ? e[e.length - 1] : '';
  const errorMessage = !!error && error.message;

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert
          severity={errorHeader === 'email-verification-sent' ? 'success' : 'error'}
          variant="standard"
        >
          <AlertTitle>{errorHeader}</AlertTitle>
          {errorMessage}
          {errorHeader === 'email-verification-needed' && (
            <div>
              Didn&apos;t receive it?&nbsp;&nbsp;
              <Link
                variant="button"
                color="primary"
                onClick={sendEmailVerification}
                style={{ cursor: 'pointer' }}
              >
                Resend
              </Link>
            </div>
          )}
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
        <IconButton className={classes.close} onClick={onClickClose}>
          <CloseIcon />
        </IconButton>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" /> */}
          <Button
            type="submit"
            disabled={submitting}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
            {submitting && (
              <CircularProgress className={classes.primaryLoading} size={20} style={{ marginLeft: 5 }} />
            )}
          </Button>
          <Grid container>
            <Grid item xs />
            <Grid item>
              <Link
                component={RouterLink}
                to={{ pathname: '/signup', state: location.state }}
                variant="body2"
              >
                Don&apos;t have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </form>
        <Button
          onClick={handleGoogleSignin}
          disabled={submitting}
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.submit}
        >
          Sign In With Google
          {submitting && (
            <CircularProgress className={classes.secondaryLoading} size={20} style={{ marginLeft: 5 }} />
          )}
        </Button>
      </div>
    </Container>
  );
};

SignIn.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(SignIn);
